import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { MtxDateFnsDatetimeModule } from '@ng-matero/extensions-date-fns-adapter';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { BundesmasterAuthorizerDirective, BundesmasterAuthorizerUserInfoComponent } from '@nx-bundesliga/bundesmaster/authorizer';
import { BundesmasterCoreModule } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterFeatureMediaLibraryModule } from '@nx-bundesliga/bundesmaster/feature-media-library';
import { de } from 'date-fns/locale';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { MatTinyMCEModule } from './components/mat-tinymce/mat-tinymce.module';
import { msalConfig, msalGuardConfig, msalInterceptorConfig } from './msal-configuration';

export const MY_FORMATS = {
	parse: {
		dateInput: 'MM/yyyy'
	},
	display: {
		dateInput: 'MM/yyyy',
		monthYearLabel: 'MMM yyyy',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM yyyy'
	}
};
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		BundesmasterCoreModule.forRoot(environment),
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		MatChipsModule,
		LayoutModule,
		MatListModule,
		MatGridListModule,
		MatCardModule,
		MatMenuModule,
		MatDateFnsModule,
		ClipboardModule,
		MatBadgeModule,
		MatRippleModule,
		MatTinyMCEModule,
		MtxDateFnsDatetimeModule,
		RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
		MsalModule.forRoot(new PublicClientApplication(msalConfig), msalGuardConfig, msalInterceptorConfig),
		BundesmasterFeatureMediaLibraryModule,
		BundesmasterAuthorizerUserInfoComponent,
		BundesmasterAuthorizerDirective
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: de
		},
		{
			provide: MAT_DATE_FNS_FORMATS,
			useValue: MY_FORMATS
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MY_FORMATS
		},
		{
			provide: DateAdapter,
			useClass: DateFnsAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{
			provide: MTX_DATETIME_FORMATS,
			useValue: {
				parse: {
					dateInput: 'yyyy-MM-dd HH:mm',
					monthInput: 'MM',
					yearInput: 'yyyy',
					timeInput: 'HH:mm',
					datetimeInput: 'yyyy-MM-dd HH:mm'
				},
				display: {
					dateInput: 'yyyy-MM-dd',
					monthInput: 'MMMM',
					yearInput: 'yyyy',
					timeInput: 'HH:mm',
					datetimeInput: 'yyyy-MM-dd HH:mm',
					monthYearLabel: 'yyyy MMMM',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM yyyy',
					popupHeaderDateLabel: 'MMM dd, ddd'
				}
			}
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService
	],
	bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
