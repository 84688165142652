import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { AssetMotifModelV1Classes } from '@nx-bundesliga/bundesmaster/core';
import { Subject } from 'rxjs';

export interface ImageMotifDialogInput {
	readonly selected: readonly string[];
}

export interface ImageMotifDialogOutput {
	readonly selected: readonly string[];
}

@Component({
	selector: 'bundesmaster-ui-image-motif-dialog',
	standalone: true,
	imports: [CommonModule, FormsModule, MatButtonModule, MatDialogModule, MatListModule],
	templateUrl: './bundesmaster-ui-image-motif-dialog.component.html',
	styleUrls: ['./bundesmaster-ui-image-motif-dialog.component.scss']
})
export class BundesmasterUiImageMotifDialogComponent implements OnInit, OnDestroy {
	private readonly destroying$ = new Subject<void>();

	public selectedMotifs = new Set<string>();

	public readonly possibleMotifs = new Set<AssetMotifModelV1Classes>([
		'action_general',
		'action_shot',
		'action_tackling',
		'celebration',
		'celebration_close',
		'celebration_far',
		'goal',
		'goal_behind',
		'goal_front',
		'goal_side',
		'person_close',
		'player_full_body',
		'player_half_body',
		'stadium',
		'no_label',
		'label_error',
		'batch_label_error'
	]);

	constructor(public dialogRef: MatDialogRef<BundesmasterUiImageMotifDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ImageMotifDialogInput) {}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	ngOnInit(): void {
		this.selectedMotifs = new Set(this.data?.selected ?? []);
	}

	selectionChange(event: MatSelectionListChange): void {
		event.options.forEach((option) => (option.selected ? this.selectedMotifs.add(option.value) : this.selectedMotifs.delete(option.value)));
	}

	public add() {
		const output: ImageMotifDialogOutput = { selected: [...this.selectedMotifs.values()] };
		this.dialogRef.close(output);
	}

	public cancel() {
		this.dialogRef.close(null);
	}
}
