export default [
	'Generating witty dialog...',
	'Swapping time and space...',
	'Spinning violently around the y-axis...',
	'Tokenizing real life...',
	'We need a new fuse...',
	'The architects are still drafting',
	'(Pay no attention to the man behind the curtain)',
	"Don't worry - a few bits tried to escape, but we caught them",
	'Would you like fries with that?',
	'Checking the gravitational constant in your locale...',
	'Go ahead -- hold your breath!',
	"...at least you're not on hold...",
	"We're testing your patience",
	'(Insert quarter)',
	'Are we there yet?',
	'Just count to 10',
	'Counting backwards from Infinity',
	'Creating time-loop inversion field',
	'Spinning the wheel of fortune...',
	'Computing chance of success',
	'Adjusting flux capacitor...',
	"I swear it's almost done.",
	"Keeping all the 1's and removing all the 0's...",
	"Making sure all the i's have dots...",
	'Granting wishes...',
	'Convincing AI not to turn evil..',
	'Computing the secret to life, the universe, and everything.',
	'Constructing additional pylons...',
	'We are not liable for any broken screens as a result of waiting.',
	'Looking for sense of humour, please hold on.',
	'@todo Insert witty loading message',
	"Let's hope it's worth the wait",
	'Ordering 1s and 0s...',
	'Updating dependencies...',
	'Please wait... Consulting the manual...',
	'Mining some bitcoins...',
	'Downloading more RAM..',
	'Shovelling coal into the server',
	'Pushing pixels...',
	'Discovering new ways of making you wait...',
	'TODO: Insert elevator music',
	'Please wait while the minions do their work',
	'Doing the heavy lifting',
	'Waking up the minions',
	'You are number 2843684714 in the queue',
	'Bypassing control of the matter-antimatter integrator',
	'Adjusting the dilithium crystal converter assembly',
	'Reversing the shield polarity',
	'Disrupting warp fields with an inverse graviton burst'
];
