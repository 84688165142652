/**
 * Courtesy of Oskar Karlsson @ https://github.com/tjoskar/ng-lazyload-image
 */
import { AfterContentInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { merge, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { fromIntersectionObserver } from './utils/intersection-observer';
import { lazyLoad, lazyLoadIntersection } from './utils/lazyload';
import { getScrollListener } from './utils/scroll-listener';
import { isWindowDefined } from './utils/utils';

export interface LazyLoadDirectiveProps {
	lazy: string;
	defaultImagePath: string;
	errorImagePath: string;
	scrollTarget: any;
	scrollObservable: Observable<Event>;
	offset: number;
	useSrcset: boolean;
	type: 'image' | 'embed';
}

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[dflLazyLoad]'
})
// @todo/dfl the entire directive can be merged with dfl-ngx-lazy-load-embed.directive
export class DflLazyLoadDirective implements OnChanges, AfterContentInit, OnDestroy {
	@Input() dflLazyLoad: any; // The element to be lazy loaded
	@Input() defaultImage: string; // The image to be displayed before lazyImage is loaded
	@Input() errorImage: string; // The image to be displayed if lazyImage load fails
	@Input() scrollTarget: any; // Scroll container that contains the image and emits scoll events
	@Input() scrollObservable; // Pass your own scroll emitter
	@Input() retry: Observable<any> = new Subject<any>(); // retries loading of image on emission if visible
	@Input() offset: number; // The number of px a image should be loaded before it is in view port
	@Input() useSrcset: boolean; // Whether srcset attribute should be used instead of src
	@Input() lazyType: 'image' | 'embed';
	@Output() elementLoaded: EventEmitter<boolean> = new EventEmitter(); // Callback when an image is loaded

	private propertyChanges$: ReplaySubject<LazyLoadDirectiveProps>;
	private elementRef: ElementRef;
	private ngZone: NgZone;
	private scrollSubscription: Subscription;
	private loaded = false;

	constructor(el: ElementRef, ngZone: NgZone) {
		this.elementRef = el;
		this.ngZone = ngZone;
		this.propertyChanges$ = new ReplaySubject();
	}

	ngOnChanges(changes?: SimpleChanges) {
		this.propertyChanges$.next({
			lazy: this.dflLazyLoad,
			defaultImagePath: this.defaultImage,
			errorImagePath: this.errorImage,
			scrollTarget: this.scrollTarget,
			scrollObservable: this.scrollObservable,
			offset: this.offset || 0,
			useSrcset: this.useSrcset,
			type: this.lazyType || 'image'
		});
	}

	ngAfterContentInit() {
		// Disable lazy load image in server side
		// @todo/dfl remove and replace with isPlatformBrowser
		if (!isWindowDefined()) {
			return null;
		}

		this.ngZone.runOutsideAngular(() => {
			let visibleObservable: Observable<boolean>;
			if (isWindowDefined() && 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
				visibleObservable = this.propertyChanges$.pipe(
					switchMap((props) =>
						fromIntersectionObserver(this.elementRef?.nativeElement, { rootMargin: '0px' /*`${props.offset}px`*/ }, 50).pipe(
							lazyLoadIntersection(this.elementRef?.nativeElement, props.type, props),
							map((loadStatus) => (props.lazy === props.defaultImagePath ? true : loadStatus))
						)
					)
				);
			} else {
				let scrollObservable: Observable<Event>;
				if (this.scrollObservable) {
					scrollObservable = this.scrollObservable.pipe(startWith(''));
				} else {
					const windowTarget = isWindowDefined() ? window : undefined;
					scrollObservable = getScrollListener(this.scrollTarget || windowTarget);
				}
				visibleObservable = this.propertyChanges$.pipe(
					debounceTime(10),
					switchMap((props) =>
						merge(this.retry, scrollObservable).pipe(
							lazyLoad(this.elementRef?.nativeElement, props.type, props),
							map((loadStatus) => (props.lazy === props.defaultImagePath ? true : loadStatus)),
							takeWhile((): boolean => this.loaded === true, true)
						)
					)
					//takeWhile((loaded) => loaded === true, true)
				);
			}

			this.scrollSubscription = visibleObservable.subscribe((success) => {
				this.ngZone.run(() => {
					this.loaded = success;
					this.elementLoaded.emit(this.loaded);
				});
			});
		});
	}

	ngOnDestroy() {
		if (this.scrollSubscription) {
			this.scrollSubscription.unsubscribe();
		}
	}
}
