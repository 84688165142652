<a *ngIf="enabledLinking === true && language && competition && playerName && playerName !== ''" [routerLink]="(['/', language, competition, 'route-player', playerName | slugify] | translateRoute)">
	<ng-container *ngTemplateOutlet="playerImageTmpl"></ng-container>
</a>
<ng-container *ngIf="enabledLinking !== true">
	<ng-container *ngTemplateOutlet="playerImageTmpl"></ng-container>
</ng-container>

<ng-template #playerImageTmpl>
	<img *ngIf="!isLoading && src && dimension && dimension > 0 && fetchPriority === 'high'" [ngSrc]="src | imageService:dimension: dimension" [alt]="playerName" [width]="dimension" [height]="dimension" priority (error)="handleMissingImage($event)" />
	<img *ngIf="!isLoading && src && dimension && dimension > 0 && fetchPriority !== 'high'" [ngSrc]="src | imageService:dimension: dimension" [alt]="playerName" [width]="dimension" [height]="dimension" (error)="handleMissingImage($event)" />
	<img *ngIf="!isLoading && src && !(dimension && dimension > 0)" [ngSrc]="src" [alt]="playerName" fill (error)="handleMissingImage($event)" />
	<img *ngIf="isLoading" [ngSrc]="this.invertPlaceholder ? this.placeHolderImgInverted : this.placeHolderImg" [alt]="playerName" fill />
</ng-template>
