import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, shareReplay, startWith } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../../bundesmaster-environment';
import { BundesmasterDataCollection, buildBundesmasterDataCollection, emptyBundesmasterDataCollection } from '../bundesmaster-data-collection';
import { BundesmasterSeason } from './bundesmaster-season';

export type BundesmasterSeasonCollection = BundesmasterDataCollection<BundesmasterSeason>;

@Injectable({
	providedIn: 'root'
})
export class BundesmasterSeasonApiService {
	private readonly baseUrl: string;

	private pendingRequest?: Observable<BundesmasterSeasonCollection>;

	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) environment: BundesmasterEnvironment) {
		this.baseUrl = environment.apis.proxy.url;
		//this.baseUrl = 'http://localhost:4987/'
	}

	private getSingleSeason(selector: (season: BundesmasterSeason) => boolean): Observable<BundesmasterSeason | null> {
		return this.getSeasons().pipe(map((seasons) => seasons.filter((season) => selector(season))[0] ?? null));
	}

	public getSeasonById(id: string): Observable<BundesmasterSeason | null> {
		return this.getSingleSeason(({ seasonId }) => seasonId === id);
	}

	public getSeasonByPath(path: string): Observable<BundesmasterSeason | null> {
		return this.getSingleSeason(({ seasonPath }) => seasonPath === path);
	}

	public getSeasonsCollection(): Observable<BundesmasterSeasonCollection> {
		this.pendingRequest =
			this.pendingRequest ??
			this.http.get<BundesmasterSeason[]>(`${this.baseUrl}/tags/seasons`).pipe(
				map((seasons) => buildBundesmasterDataCollection(seasons)),
				startWith(emptyBundesmasterDataCollection<BundesmasterSeason>('loading')),
				shareReplay(1)
			);

		return this.pendingRequest;
	}

	public getSeasons(): Observable<readonly BundesmasterSeason[]> {
		return this.getSeasonsCollection().pipe(map(({ items }) => items));
	}
}
