export type BundesmasterUserRoleName = 'bundesmaster.guest' | 'bundesmaster.viewer' | 'bundesmaster.contributor' | 'bundesmaster.editor' | 'bundesmaster.admin' | 'bundesmaster.dev';
export type BundesmasterUserRoleNameNoRole = 'bundesmaster.no_role';

export class BundesmasterUserRole<TAsString extends BundesmasterUserRoleName | BundesmasterUserRoleNameNoRole = BundesmasterUserRoleName | BundesmasterUserRoleNameNoRole> {
	public readonly displayName: string;

	private constructor(public readonly fullName: TAsString, public readonly powerLevel: number) {
		this.displayName = fullName.replace('bundesmaster.', '');
	}

	public static readonly GUEST = new BundesmasterUserRole('bundesmaster.guest', 0);
	public static readonly VIEWER = new BundesmasterUserRole('bundesmaster.viewer', 1);
	public static readonly CONTRIBUTOR = new BundesmasterUserRole('bundesmaster.contributor', 2);
	public static readonly EDITOR = new BundesmasterUserRole('bundesmaster.editor', 3);
	public static readonly ADMIN = new BundesmasterUserRole('bundesmaster.admin', 4);
	public static readonly DEV = new BundesmasterUserRole('bundesmaster.dev', 5);
	public static readonly NO_ROLE = new BundesmasterUserRole('bundesmaster.no_role', -Infinity);

	public get isGuest() {
		return this.fullName === 'bundesmaster.guest';
	}

	public get isViewer() {
		return this.fullName === 'bundesmaster.viewer';
	}

	public get isContributor() {
		return this.fullName === 'bundesmaster.contributor';
	}

	public get isEditor() {
		return this.fullName === 'bundesmaster.editor';
	}

	public get isAdmin() {
		return this.fullName === 'bundesmaster.admin';
	}

	public get isDev() {
		return this.fullName === 'bundesmaster.dev';
	}

	public is(role: BundesmasterUserRole): boolean {
		return this.fullName === role.fullName;
	}

	public toString() {
		return this.displayName;
	}

	private static readonly KnownRoles: ReadonlyMap<string, BundesmasterUserRole> = new Map<string, BundesmasterUserRole>([this.GUEST, this.VIEWER, this.CONTRIBUTOR, this.EDITOR, this.ADMIN, this.DEV].map((role) => [role.fullName, role]));

	public static getMostPowerfulRole(roles: readonly BundesmasterUserRole[] = []): BundesmasterUserRole {
		if (roles.length === 0) {
			return this.NO_ROLE;
		}

		return [...roles].sort((a, b) => b.powerLevel - a.powerLevel)[0];
	}

	public static getFromNames(roleNames: readonly string[] = []): BundesmasterUserRole[] {
		return (roleNames ?? []).map((roleName) => this.tryGetFromName(roleName)).filter((role): role is BundesmasterUserRole => role !== null);
	}

	public static tryGetFromName(roleName: string): BundesmasterUserRole | null {
		return this.KnownRoles.get(roleName) ?? null;
	}
}

export const DEV = BundesmasterUserRole.DEV;
export const ADMIN = BundesmasterUserRole.ADMIN;
export const EDITOR = BundesmasterUserRole.EDITOR;
export const CONTRIBUTOR = BundesmasterUserRole.CONTRIBUTOR;
export const VIEWER = BundesmasterUserRole.VIEWER;
export const GUEST = BundesmasterUserRole.GUEST;

function getHighestPowerLevel(powerLevel: number, role: BundesmasterUserRole | null) {
	if (role && role.powerLevel > powerLevel) {
		return role.powerLevel;
	}

	return powerLevel;
}

export function toHighestPowerLevel(roles?: string[]) {
	return roles?.map((role) => BundesmasterUserRole.tryGetFromName(role)).reduce(getHighestPowerLevel, 0) ?? 0;
}
