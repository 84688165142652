import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BundesmasterLanguageRole, BundesmasterUserRole } from '@nx-bundesliga/bundesmaster/shared';
import { BehaviorSubject, Observable, OperatorFunction, ReplaySubject, UnaryFunction, filter, map, pipe } from 'rxjs';
import { BundesmasterUser } from '../types';

@Injectable({
	providedIn: 'root'
})
export class UserAuthenticationService {
	readonly user$: ReplaySubject<BundesmasterUser | null> = new ReplaySubject(1);
	readonly isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService) {
		msalBroadcastService.msalSubject$
			.pipe(
				takeUntilDestroyed(),
				filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
			)
			.subscribe((result) => {
				this.#log('DEBUG', 'msal:event', result);
			});

		msalBroadcastService.inProgress$
			.pipe(
				takeUntilDestroyed(),
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				map(() => {
					const activeAccount = this.authService.instance.getActiveAccount();
					if (activeAccount) {
						return activeAccount;
					}

					const accounts = this.authService.instance.getAllAccounts();
					if (accounts.length > 0) {
						this.authService.instance.setActiveAccount(accounts[0]);
						return accounts[0];
					}

					return undefined;
				}),
				filterNullish(),
				map(
					(user: AccountInfo): BundesmasterUser => ({
						id: user.localAccountId,
						name: user.name ?? user.username,
						mail: user.username,
						role: BundesmasterUserRole.getMostPowerfulRole(BundesmasterUserRole.getFromNames(user.idTokenClaims?.roles ?? [])),
						roles: BundesmasterUserRole.getFromNames(user.idTokenClaims?.roles ?? []),
						allowedLanguages: BundesmasterLanguageRole.getFromRoles(user.idTokenClaims?.roles ?? [])
					})
				)
			)
			.subscribe({
				next: (user) => {
					this.#log('DEBUG', 'msal:user', user);
					this.isLoggedIn$.next(true);
					this.user$.next(user);
				},

				error: (error) => {
					this.#log('ERROR', error);
					this.user$.next(null);
					this.isLoggedIn$.next(false);
				},

				complete: () => {
					this.#log('DEBUG', 'COMPLETE');

					this.user$.next(null);
					this.isLoggedIn$.next(false);
				}
			});
	}

	logout() {
		this.isLoggedIn$.next(false);
		this.authService.logout();
	}

	login() {
		this.authService.loginRedirect();
	}

	#log(logLevel: string, ...data: any[]) {
		console.log(`%c [MSAL][${logLevel}] `, 'background: #FC1948; color: #fff', ...data);
	}
}

export function filterNullish<T>(): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
	return pipe(filter((x) => x != null) as OperatorFunction<T | null | undefined, T>);
}
