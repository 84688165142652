import { Injectable, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Bypasses Angulars xss/security functionality and declares the given url as safe.
 */
@Injectable()
@Pipe({ name: 'safe', pure: true })
export class SafeUrlPipe implements PipeTransform {
	constructor(protected sanitizer: DomSanitizer) {}

	transform(url?: string): SafeResourceUrl {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}

/**
 * Bypasses Angulars xss/security functionality and declares the given html as safe.
 */
@Injectable()
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	constructor(protected sanitizer: DomSanitizer) {}

	transform(html?: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}

@Injectable()
@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform {
	constructor(protected sanitizer: DomSanitizer) {}

	transform(html?: string): SafeHtml {
		return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(html));
	}
}
