import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
	constructor(private zone: NgZone) {}

	handleError(error: any): void {
		this.zone.run(() => {
			console.log(error);
		});
	}
}
