import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { roleBasedAuthorizerGuard } from '@nx-bundesliga/bundesmaster/authorizer';
import { BundesmasterUserRole } from '@nx-bundesliga/bundesmaster/shared';

export const appRoutes: Route[] = [
	{
		path: 'home',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.EDITOR)],
		loadChildren: () => import('@nx-bundesliga/bundesmaster/feature-homepage-control').then((mod) => mod.BundesmasterFeatureHomepageControlModule)
	},
	{
		path: 'article',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.GUEST)],
		loadChildren: () => import('./article/article.module').then((mod) => mod.ArticleModule)
	},
	{
		path: 'matchreport',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.EDITOR)],
		loadChildren: () => import('./matchreport/matchreport.module').then((mod) => mod.MatchreportModule)
	},
	{
		path: 'articles',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.GUEST)], //
		loadChildren: () => import('./articles/article-list.module').then((mod) => mod.ArticleListModule)
	},
	{
		path: 'videos',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.VIEWER)], //
		loadChildren: () => import('./video-library/video-library.module').then((mod) => mod.VideoLibraryModule)
	},
	{
		path: 'story',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.GUEST)], //
		loadChildren: () => import('./story/story.module').then((mod) => mod.StoryModule)
	},
	{
		path: 'media',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.VIEWER)], //
		loadChildren: () => import('./media-library/media-library.module').then((mod) => mod.MediaLibraryModule)
	},
	{
		path: 'barfinder',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.EDITOR)], //
		loadChildren: () => import('@nx-bundesliga/bundesmaster/feature-barfinder').then((mod) => mod.BundesmasterBarfinderModule)
	},
	{
		path: 'broadcasters',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.EDITOR)], //
		loadChildren: () => import('@nx-bundesliga/bundesmaster/feature-epg-broadcasters').then((mod) => mod.BundesmasterEpgBroadcastersModule)
	},
	{
		path: 'clubs',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.EDITOR)], //
		loadChildren: () => import('./clubs/clubs.module').then((mod) => mod.ClubsModule)
	},
	{
		path: 'player-center',
		canActivate: [MsalGuard, roleBasedAuthorizerGuard(BundesmasterUserRole.EDITOR)], //
		loadChildren: () => import('@nx-bundesliga/bundesmaster/player-center/feature').then((mod) => mod.PLAYER_CENTER_ROUTES)
	},
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	{ path: 'hall-of-fame', loadComponent: () => import('./hall-of-fame/hall-of-fame.component').then((mod) => mod.HallOfFameComponent) },
	{ path: 'login', loadComponent: () => import('./welcome/welcome.component').then((mod) => mod.WelcomeComponent) },
	{ path: '**', loadComponent: () => import('./not-found/not-found.component').then((mod) => mod.NotFoundComponent) }
];
