import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-teaser-preview',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './bundesmaster-ui-teaser-preview.component.html',
	styleUrls: ['./bundesmaster-ui-teaser-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundesmasterUiTeaserPreviewComponent {}
