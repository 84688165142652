import { createReducer, on } from '@ngrx/store';
import { State as CoreState } from '../core.reducers';
import { setArticleLanguage } from './article-language.actions';

export const articleLanguageFeatureKey: keyof CoreState = 'articleLanguage';

export interface ArticleLanguageState {
	language: string | null;
}

export const articleLanguageReducer = createReducer<ArticleLanguageState>(
	{ language: null },
	on(setArticleLanguage, (_, { language }) => ({ language }))
);
