import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Asset, AssetDimensions, AssetDimensionsProperties, assetDimensions } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterMediaLibraryUploadItem } from '@nx-bundesliga/bundesmaster/core';

export interface DialogSelectMediaData {
	dimensions?: AssetDimensions[];
	selectedDimensions?: AssetDimensions[];
	hideDimensions?: boolean;
}

@Component({
	selector: 'nx-bundesliga-media-dialog-select-media',
	templateUrl: './dialog-select-media.component.html',
	styleUrls: ['./dialog-select-media.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogSelectMediaComponent implements OnDestroy {
	public dimensions: { [dimension in AssetDimensions]?: AssetDimensionsProperties } = assetDimensions;
	public file?: File;
	public form = this.fb.group({
		dimensions: this.fb.array([])
	});
	constructor(public dialogRef: MatDialogRef<DialogSelectMediaComponent>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: DialogSelectMediaData, private router: Router, private route: ActivatedRoute) {
		if (this.data?.dimensions?.length > 0) {
			const selectedDimensions = Object.entries(assetDimensions).filter(([key]) => this.data.dimensions.map((d) => d.toString()).includes(key));
			this.dimensions = selectedDimensions.reduce((acc, [key, val]) => {
				acc[key] = val;
				return acc;
			}, {});
		}
		const selectedDimensions = (this.data?.selectedDimensions as string[]) || [];
		Object.keys(this.dimensions).forEach((val: AssetDimensions) => {
			(this.form.get('dimensions') as FormArray).push(new FormControl(selectedDimensions.includes(val)));
		});
	}

	public imageSelected(asset: Asset) {
		this.dialogRef.close({
			asset: asset,
			dimensions: this.form
				.get('dimensions')
				.value.map((dimension: boolean, i: number) => (dimension ? Object.keys(this.dimensions)[i] : null))
				.filter((dim) => dim !== null)
		});
	}

	public fileSelected(file: File) {
		this.file = file;
	}

	public fileUploaded(item: BundesmasterMediaLibraryUploadItem) {
		const asset: Asset = {
			id: item.contentId,
			created_date: item.createdAt,
			author: [item.author],
			media_type: 'image',
			mime_type: item.contentType,
			file: item.path,
			file_size: item.payload.size,
			copyright: item.payload.copyright,
			caption: item.payload.caption,
			image: {
				width: item.payload.width,
				height: item.payload.height
			}
		};

		this.imageSelected(asset);
		this.file = null;
	}

	ngOnDestroy() {
		// remove added queryparams from url
		this.router.navigate([], {
			relativeTo: this.route
		});
	}
}
