import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { BrowserCacheLocation, Configuration, InteractionType } from '@azure/msal-browser';
import { getStageDomain } from '@nx-bundesliga/shared/util/functions';
import { environment } from '../environments/environment';

const getProtectedResourceMap = (clientId: string, stage: string): Map<string, Array<string | ProtectedResourceScopes> | null> => {
	const map = ['content-api', 'api', 'ai.api', 'stories.api', 'player-center.api'].reduce((acc, api) => {
		acc.set(`https://${api}.bundesmaster.` + getStageDomain(stage), [`api://${clientId}/Bundesmaster.Master`]);
		return acc;
	}, new Map([['https://graph.microsoft.com', ['User.Read', 'profile']]]));

	return map;
};

export const msalGuardConfig: MsalGuardConfiguration = {
	interactionType: InteractionType.Redirect
};

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
	interactionType: InteractionType.Redirect,
	protectedResourceMap: getProtectedResourceMap(environment.msal.clientId, environment.stage)
};

export const msalConfig: Configuration = {
	auth: {
		clientId: environment.msal.clientId,
		authority: `https://login.microsoftonline.com/${environment.msal.authority}`,
		redirectUri: environment.msal.loginUrl
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage,
		storeAuthStateInCookie: true // set to true for IE 11
	},
	system: {
		loggerOptions: {
			piiLoggingEnabled: false
		}
	}
};
