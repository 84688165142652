import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Inject, Input, NgZone, OnDestroy, OnInit, Optional, PLATFORM_ID, Self } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ArticleContentBlockMoveNotificationService } from '@nx-bundesliga/bundesmaster/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { Subject, Subscription } from 'rxjs';
import { MatTinyMceAutoCompletionService } from './mat-tinymce-autocompletion.service';

const SELECTOR = 'mat-tinymce';
const VALID_HTML_ELEMENTS = ['div,p,br,a[!href|target|class],ul,ol,li,strong/b,em/i'];
const VALID_HTML_ELEMENTS_TABLE = 'table,td,tr,tbody,colgroup';
const TINYMCE_PLUGINS = ['link', 'autolink', 'lists', 'wordcount', 'quickbars'];
const TINYMCE_TOOLBAR = ['bold italic removeformat', 'undo redo', 'cut copy paste pastetext', 'bullist numlist', 'link unlink openlink', 'insertPlayer'];

abstract class _MatTinyMCEComponentBase extends EditorComponent {
	stateChanges = new Subject<void>();
	constructor(public _defaultErrorStateMatcher: ErrorStateMatcher, public _parentForm: NgForm, public _parentFormGroup: FormGroupDirective, public ngControl: NgControl, elementRef: ElementRef, ngZone: NgZone, platformId: any) {
		super(elementRef, ngZone, platformId);
	}
}

@Component({
	selector: SELECTOR,
	template: '<ng-template></ng-template>',
	exportAs: 'matTinyMCE',
	providers: [{ provide: MatFormFieldControl, useExisting: MatTinyMCEComponent }],
	styleUrls: ['mat-tinymce.component.scss']
})
export class MatTinyMCEComponent extends mixinErrorState(_MatTinyMCEComponentBase) implements MatFormFieldControl<string>, OnDestroy, OnInit {
	@Input() enabledModules: ('table' | 'iframe' | 'link_classes')[] = [];
	private static nextId = 0;
	private _placeholder = '';
	private _required = false;

	public override id = `${SELECTOR}-${MatTinyMCEComponent.nextId++}`;
	@HostBinding('attr.aria-describedBy')
	public describedBy = '';
	private contentBlockMovedSubscription?: Subscription;
	private autoCompletions: MatTinyMceAutoCompletionService;

	public constructor(
		_defaultErrorStateMatcher: ErrorStateMatcher,
		@Optional() _parentForm: NgForm,
		@Optional() _parentFormGroup: FormGroupDirective,
		@Optional() @Self() ngControl: NgControl,
		elementRef: ElementRef,
		ngZone: NgZone,
		autoCompletions: MatTinyMceAutoCompletionService,
		@Inject(PLATFORM_ID) platformId: any,
		contentBlockMovedService: ArticleContentBlockMoveNotificationService
	) {
		super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, elementRef, ngZone, platformId);
		if (this.ngControl) {
			this.ngControl.valueAccessor = this;
		}

		this.autoCompletions = autoCompletions;

		// If the DOM element containing the editor is moved, the editor becomes unusable and needs to be re-initialized
		// See https://github.com/tinymce/tinymce/issues/1073
		this.contentBlockMovedSubscription = contentBlockMovedService.blockMoved().subscribe((formGroups) => {
			if (formGroups.has(_parentFormGroup?.form)) {
				if (this.editor && this.editor.initialized) {
					const savedValue = this.value;
					this.editor.remove();
					this.initialValue = savedValue;
					this.initialise();
				}
			}
		});
	}

	ngOnInit() {
		const plugins = [...TINYMCE_PLUGINS];
		const toolbar = [...TINYMCE_TOOLBAR];
		const valid_elements = [...VALID_HTML_ELEMENTS];
		const link_class_list = [];
		const valid_classes = {};
		let allow_script_urls = false;

		if (this.enabledModules.includes('table')) {
			valid_elements.push(VALID_HTML_ELEMENTS_TABLE);
			toolbar.push('table');
			plugins.push('table');
		}

		if (this.enabledModules.includes('iframe')) {
			valid_elements.push('iframe');
		}

		if (this.enabledModules.includes('link_classes')) {
			link_class_list.push({ title: 'None', value: '' }, { title: 'OneTrust Settings', value: 'ot-sdk-show-settings' });
			valid_classes['a'] = 'ot-sdk-show-settings';
			allow_script_urls = true;
		}

		this.init = {
			plugins,
			link_class_list,
			toolbar: toolbar.join(' | '),
			valid_elements: valid_elements.join(','),
			valid_classes,
			base_url: '/tinymce',
			suffix: '.min',
			allow_script_urls,
			link_target_list: [
				{ title: 'Default', value: '' },
				{ title: 'Same page', value: '_self' },
				{ title: 'New page', value: '_blank' },
				{ title: 'Parent frame', value: '_parent' }
			],

			branding: false,
			promotion: false,
			elementpath: false,
			menubar: false,
			link_title: false,
			quickbars_insert_toolbar: false,
			quickbars_selection_toolbar: 'bold italic removeformat | link unlink openlink',
			entity_encoding: 'raw',
			content_style: '.mce-content-readonly, .mce-content-readonly a {color: rgba(0, 0, 0, 0.38);}',
			table_toolbar: 'tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

			setup: (editor) => {
				editor.ui.registry.addAutocompleter('persons', {
					ch: '@',
					onAction: (autocompleteApi, rng, link) => {
						editor.selection.setRng(rng);
						editor.insertContent(link);
						autocompleteApi.hide();
					},
					fetch: (input) => this.autoCompletions.getAutoCompletions(input)
				});
			}
		};
	}

	public set value(value: string) {
		this.writeValue(value);
	}

	public get value(): string {
		return this.editor && this.editor.initialized ? this.editor.getContent() : this.initialValue;
	}

	public get empty(): boolean {
		return !this.value;
	}

	@Input()
	public set placeholder(placeholder: string) {
		this._placeholder = placeholder;
		this.stateChanges.next();
	}

	public get placeholder() {
		return this._placeholder;
	}

	@Input()
	public set required(required: any) {
		this._required = coerceBooleanProperty(required);
		this.stateChanges.next();
	}

	public get required() {
		return this._required;
	}

	public get focused(): boolean {
		return !!(this.editor && this.editor.hasFocus());
	}

	@HostBinding('class.floating')
	public get shouldLabelFloat(): boolean {
		return this.focused || !this.empty;
	}

	public onContainerClick(): void {
		if (!this.focused) {
			this.focus();
		}
	}

	public focus(): void {
		if (!this.editor) return;
		this.editor.focus();
	}

	public setDescribedByIds(ids: string[]): void {
		this.describedBy = ids.join(' ');
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.contentBlockMovedSubscription?.unsubscribe();
	}
}
