import { Club } from '../../club/club.model';
import { BUNDESLIGA_SEASONS } from '../../bundesliga/common.model';

export interface ClubStateData {
	'bundesliga': Club[];
	'2bundesliga': Club[];
}

export interface ClubState {
	[season: string]: {
		data: ClubStateData;
		loaded: boolean;
		loading: boolean;
		error: string;
	};
}

export const initialAppstateClub: ClubState = {
	[BUNDESLIGA_SEASONS.CURRENT]: {
		data: {
			'bundesliga': [],
			'2bundesliga': []
		},
		loaded: false,
		loading: false,
		error: null
	}
};
