export type BundesmasterDataCollectionStatus = 'loading' | 'empty' | 'ready';

export interface BundesmasterDataCollection<TItem> {
	readonly status: BundesmasterDataCollectionStatus;
	readonly items: readonly TItem[];
	readonly partial: BundesmasterPartialCollection;
}

export interface BundesmasterPartialCollection {
	readonly status: 'allItemsIncluded' | 'partialWithTotalItems' | 'partialWithMoreThanTotalItems';
	readonly returnedItems: number;
	readonly totalItems: number;
}

export function emptyBundesmasterDataCollection<TItem>(status: BundesmasterDataCollectionStatus): BundesmasterDataCollection<TItem> {
	return {
		status,
		partial: BUNDESMASTER_PARTIAL_COLLECTION_EMPTY,
		items: []
	};
}

export function buildBundesmasterDataCollection<TItem>(items: readonly TItem[], partial?: BundesmasterPartialCollection): BundesmasterDataCollection<TItem> {
	partial = partial ?? { status: 'allItemsIncluded', returnedItems: items.length, totalItems: items.length };

	return {
		items,
		status: items.length === 0 ? 'empty' : 'ready',
		partial
	};
}

export const BUNDESMASTER_PARTIAL_COLLECTION_EMPTY: BundesmasterPartialCollection = {
	returnedItems: 0,
	status: 'allItemsIncluded',
	totalItems: 0
};
