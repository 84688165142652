import { createReducer, on } from '@ngrx/store';
import { competitions, Competitions } from '../../models/bundesliga.competition.model';
import * as competitionActions from './competition.actions';

export const competitionFeatureKey = 'competition';

export interface State {
	available: Competitions;
	active: string;
}
export const initialState: State = {
	available: competitions,
	active: competitions['bundesliga'].name
};

export const reducer = createReducer(
	initialState,
	on(competitionActions.setCompetition, (state, payload) => ({
		...state,
		active: payload.name
	}))
);
