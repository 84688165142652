import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';

@Injectable({
	providedIn: 'root'
})
export class BundesmasterProxyApiService {
	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}

	setHome(config: any, competitionNumber: number, language: string) {
		const headers = new HttpHeaders().append('Content-Type', 'application/json').append('Accept-Language', language);
		return this.http.post(`${this.environment.apis.proxy.url}/homepage-order/${competitionNumber}`, config, { headers });
	}

	// addCity(city: BundesligaPlacesCity) {
	addCity(city: any) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		delete city.id; // wasn't me 🤞🏻
		console.log('addCity: ' + JSON.stringify(city));
		return this.http.post(`${this.environment.apis.proxy.url}/barfinder/cities`, city);
	}

	deleteCity(id: string) {
		return this.http.delete(`${this.environment.apis.proxy.url}/barfinder/cities/${id}`);
	}

	// updateCity(id: string, city: BundesligaPlacesCity) {
	updateCity(id: string, city: any) {
		return this.http.put(`${this.environment.apis.proxy.url}/barfinder/cities/${id}`, city);
	}

	// addBar(bar: BundesligaPlace) {
	addBar(bar: any) {
		return this.http.post(`${this.environment.apis.proxy.url}/barfinder/`, bar);
	}

	// updateBar(id: string, bar: BundesligaPlace) {
	updateBar(id: string, bar: any) {
		return this.http.put(`${this.environment.apis.proxy.url}/barfinder/${id}`, bar);
	}

	deleteBar(id: string) {
		return this.http.delete(`${this.environment.apis.proxy.url}/barfinder/${id}`);
	}
}
