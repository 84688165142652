import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BLACKLISTED_COMPETITION_IDS } from '@nx-bundesliga/bundesmaster/shared';
import { Observable, map, of, shareReplay, startWith } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../../bundesmaster-environment';
import { BundesmasterDataCollection, buildBundesmasterDataCollection } from '../bundesmaster-data-collection';
import { BundesmasterClub } from './bundesmaster-club';

export type BundesmasterClubCollection = BundesmasterDataCollection<BundesmasterClub> & { readonly itemMap: ReadonlyMap<string, BundesmasterClub> };

export interface ClubFilter {
	readonly seasonId?: string;
	readonly competitionId?: string;
	readonly name?: string;

	// include blacklisted clubs (for example EURO24 clubs)? Default is false
	readonly includeBlacklisted?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class BundesmasterClubApiService {
	private pendingRequest?: Observable<Pick<BundesmasterClubCollection, 'items' | 'status'>> | null = null;

	private readonly baseUrl: string;

	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) environment: BundesmasterEnvironment) {
		this.baseUrl = environment.apis.proxy.url;
		//this.baseUrl = 'http://localhost:4987'
	}

	public getClub(clubId: string): Observable<BundesmasterClub | null> {
		if (typeof clubId !== 'string') {
			return of(null);
		}

		return this.getClubsCollection({ includeBlacklisted: true }).pipe(map(({ itemMap }) => itemMap.get(clubId) ?? null));
	}

	public getClubs(filter?: ClubFilter): Observable<readonly BundesmasterClub[]> {
		return this.getClubsCollection(filter).pipe(map(({ items }) => items));
	}

	private filterClub({ seasons, allNames, competitions }: BundesmasterClub, { competitionId, seasonId, name, includeBlacklisted }: ClubFilter = {}): boolean {
		seasonId = (seasonId ?? '').trim();
		competitionId = (competitionId ?? '').trim();
		name = (name ?? '').trim();

		includeBlacklisted = includeBlacklisted ?? false;

		const nameMatches = allNames.some((clubName) => clubName.toLocaleLowerCase().includes(name.toLocaleLowerCase()));

		const wasActiveInSeason = seasonId.length === 0 || seasons[seasonId] != null;

		const wasActiveInCompetition = competitionId.length === 0 || (seasonId.length === 0 && (competitions ?? []).some((competition) => competitionId === competition)) || (seasons[seasonId] ?? []).some((competition) => competition === competitionId);

		const isBlacklisted = competitions.every((c) => BLACKLISTED_COMPETITION_IDS.has(c));

		return nameMatches && wasActiveInSeason && wasActiveInCompetition && (!isBlacklisted || includeBlacklisted);
	}

	public getClubsCollection(clubFilter?: ClubFilter): Observable<BundesmasterClubCollection> {
		if (this.pendingRequest === null) {
			this.pendingRequest = this.http.get<readonly BundesmasterClub[]>(`${this.baseUrl}/tags/clubs`).pipe(
				map((items) => ({ items, status: items.length === 0 ? ('empty' as const) : ('ready' as const) })),
				startWith({ items: [], status: 'loading' as const }),
				shareReplay(1)
			);
		}

		return this.pendingRequest.pipe(
			map(({ items, status }) => ({ status, items: items.filter((club) => this.filterClub(club, clubFilter)) })),
			map(({ items, status }) => ({
				...buildBundesmasterDataCollection(items),
				itemMap: new Map(items.map((club) => [club.clubId, club])),
				status: status === 'loading' ? 'loading' : items.length === 0 ? 'empty' : 'ready'
			}))
		);
	}
}
