import { ArticleContentBlockImage, ArticleContentBlocks, ArticleContentBlockVideo } from '../models/article.model';

export function getImageFromContentBlock(content: ArticleContentBlocks[]): string {
	const image = content.filter((block: ArticleContentBlocks) => block.blockType === 'image' || block.blockType === 'video');
	const firstImage = image[0] as ArticleContentBlockImage | ArticleContentBlockVideo;

	if (firstImage?.image.desktop?.url) {
		return firstImage.image.desktop.url;
	}

	return '/assets/logo/fallback-bundesliga.jpg';
}
