
    export const buildVars = {
      version: '1.7.2',
      build: '9026',
      angularVersion: '16.2.12',
	  nxVersion: '17.0.3',
      commitId: '1ead13a53',
      commitTime: '2024-10-09 14:55:29 +0000',
	  branch: 'feature/epg-access'
    };
    