import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fetchProviderId'
})
export class FetchProviderIdPipe implements PipeTransform {
	transform(playerId: string): string {
		return 'NO ID FOUND';
	}
}
