import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BundesmasterClubApiService, BundesmasterMatchApiService, BundesmasterMatchdayApiService, BundesmasterPersonApiService, BundesmasterSeasonApiService } from '../../services/bundesmaster-data-api';
import { ResolveClubIdPipe } from './resolve-club-id.pipe';
import { ResolveCompetitionIdPipe } from './resolve-competition-id.pipe';
import { ResolveMatchIdPipe } from './resolve-match-id.pipe';
import { ResolveMatchDayIdPipe } from './resolve-matchday-id.pipe';
import { ResolvePersonIdPipe } from './resolve-person-id.pipe';
import { ResolveSeasonIdPipe } from './resolve-season-id.pipe';
@Pipe({
	name: 'tryResolveAnyStsId'
})
export class TryResolveAnyIdPipe implements PipeTransform {
	private readonly delegatePipes: Record<string, { transform(id: string): Observable<string> }> = {
		CLU: new ResolveClubIdPipe(this.clubService),
		MAT: new ResolveMatchIdPipe(this.matchService),
		DAY: new ResolveMatchDayIdPipe(this.matchdayService),
		OBJ: new ResolvePersonIdPipe(this.personService),
		COM: new ResolveCompetitionIdPipe(),
		SEA: new ResolveSeasonIdPipe(this.seasonService)
	};

	constructor(
		private readonly matchdayService: BundesmasterMatchdayApiService,
		private readonly personService: BundesmasterPersonApiService,
		private readonly seasonService: BundesmasterSeasonApiService,
		private readonly clubService: BundesmasterClubApiService,
		private readonly matchService: BundesmasterMatchApiService
	) {}

	transform(anyId: string): Observable<string> {
		const idType = /(-(?<idType>.{3})-)/.exec(anyId)?.groups?.['idType'];
		return this.delegatePipes[idType]?.transform(anyId) ?? of(anyId);
	}
}
