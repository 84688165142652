import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

export interface SortEvent {
	sort: string;
	order: 'asc' | 'desc';
}

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-mat-paginator-goto',
	standalone: true,
	imports: [CommonModule, FormsModule, MatPaginatorModule, MatSelectModule],
	templateUrl: './bundesmaster-ui-mat-paginator-goto.component.html',
	styleUrls: ['./bundesmaster-ui-mat-paginator-goto.component.scss']
})
export class BundesmasterUiMatPaginatorGotoComponent implements OnInit {
	public _sort: string = null;
	public _order: 'asc' | 'desc' = 'desc';
	public _pageSize = 24;
	public _pageIndex = 0;
	public _length = 0;
	public pageNumbers: number[] = [];
	@ViewChild(MatPaginator) paginator!: MatPaginator;
	@Input() disabled = false;
	@Input() hidePageSize = false;
	@Input() hideSort = false;
	@Input() pageSizeOptions: number[] = [];
	@Input() sortOptions: string[] = [];
	@Input() showFirstLastButtons = false;
	@Output() page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
	@Output() sort: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('sort') set sortChanged(sort: string) {
		this._sort = sort;
	}
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('order') set orderChanged(order: 'asc' | 'desc') {
		this._order = order;
	}
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('pageIndex') set pageIndexChanged(pageIndex: number) {
		this._pageIndex = pageIndex;
		this.updatePageList();
	}
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('length') set lengthChanged(length: number) {
		this._length = length;
		this.updatePageList();
	}
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('pageSize') set pageSizeChanged(pageSize: number) {
		this._pageSize = pageSize;
		this.updatePageList();
	}

	ngOnInit() {
		this.updatePageList();
	}

	orderChange(selectionEvent: MatSelectChange) {
		const event: SortEvent = {
			sort: this._sort,
			order: this._order
		};
		this.emitSortEvent(event);
	}
	sortChange(selectionEvent: MatSelectChange) {
		const event: SortEvent = {
			sort: this._sort,
			order: this._order
		};
		this.emitSortEvent(event);
	}

	updatePageList() {
		if (this._length && this._pageSize) {
			this.pageNumbers = [];
			for (let i = 0; i < Math.ceil(this._length / this._pageSize); i++) {
				this.pageNumbers.push(i);
			}
		}
	}

	paginationChange(pageEvt: PageEvent) {
		this._length = pageEvt.length;
		this._pageIndex = pageEvt.pageIndex;
		this._pageSize = pageEvt.pageSize;
		this.updatePageList();
		this.emitPageEvent(pageEvt);
	}

	goToChange(selectionEvent: MatSelectChange) {
		this.paginator.pageIndex = selectionEvent.value;
		const event: PageEvent = {
			length: this.paginator.length,
			pageIndex: this.paginator.pageIndex,
			pageSize: this.paginator.pageSize
		};
		this.emitPageEvent(event);
	}

	emitPageEvent(pageEvent: PageEvent) {
		this.page.emit(pageEvent);
	}
	emitSortEvent(sortEvent: SortEvent) {
		this.sort.emit(sortEvent);
	}
}
