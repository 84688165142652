import { buildVars } from './build_vars';
import { BundesmasterAppEnvironment } from './bundesmaster-app-environment';

export const environment: BundesmasterAppEnvironment = {
	buildVars,
	stage: 'development',
	domain: 'https://www.bundesliga-dev.com',
	msal: {
		loginUrl: 'https://bundesmaster.bundesliga-dev.com/login',
		clientId: 'b9865e8d-3dcc-4f8d-9580-fb9dc861107e',
		authority: '89a6caa2-899f-4ef6-8e7f-c4c2d2a4220f'
	},
	gmaps: {
		key: 'AIzaSyCkHcIo_UOxd80r6HOKTGqqT0g8WEKoYuk'
	},
	jw: {
		playlist: {
			goals: {
				dach: 'qGuv3ENY',
				nondach: 'ZosXLSkY'
			},
			player: 'ReJQNyhH'
		}
	},
	facebook: {
		appId: '397112304023421'
	},
	apis: {
		ai: {
			url: 'https://ai.api.bundesmaster.bundesliga-dev.com'
		},
		assets: {
			url: 'https://assets.bundesliga-dev.com'
		},
		stories: {
			url: 'https://stories.api.bundesmaster.bundesliga-dev.com'
		},
		proxy: {
			url: 'https://api.bundesmaster.bundesliga-dev.com'
		},
		content: {
			url: 'https://content-api.bundesmaster.bundesliga-dev.com'
		},
		wapp: {
			url: 'https://wapp.bapi.bundesliga-dev.com',
			key: 'XPU9UxAzKh0396AU-DEV'
		},
		playerCenter: {
			url: 'https://player-center.api.bundesmaster.bundesliga-dev.com'
		}
	},
	videoLibrary: {
		jwPlayerId: '3qIK4vrl'
	}
};
