import { createReducer, on } from '@ngrx/store';
import { State as CoreState } from '../core.reducers';
import { setBroadcasterSearch } from './broadcaster-search.actions';

export const broadcasterSearchFeatureKey: keyof CoreState = 'broadcasterSearch';

export interface BroadcasterSearchState {
	country?: string;
	query?: string;
}

export const broadcasterSearchReducer = createReducer<BroadcasterSearchState>(
	{},
	on(setBroadcasterSearch, (_, { country, query }) => ({ country, query }))
);
