
    export const buildVars = {
      version: '1.7.1',
      build: 'local',
      angularVersion: '16.2.12',
	  nxVersion: '17.0.3',
      commitId: '2ddc67d73',
      commitTime: '2024-09-18 13:01:58 +0200',
	  branch: 'matchreport-ai-gen'
    };
    