<h2 mat-dialog-title>Select Players</h2>

<ng-template #loading>
	<mat-progress-spinner class="d-flex justify-content-center flex-grow-1" diameter="48" color="primary" mode="indeterminate"></mat-progress-spinner>
</ng-template>

<mat-dialog-content class="mat-typography">
	<div class="d-flex flex-row gap" [formGroup]="form">
		<mat-form-field class="flex-grow-1">
			<mat-label>Name, alias oder playerID</mat-label>
			<input matInput formControlName="query" placeholder="Start typing.." type="text" data-lpignore="true" cdkFocusInitial />
		</mat-form-field>

		<nx-bundesliga-bundesmaster-ui-season-selector formControlName="seasonId"></nx-bundesliga-bundesmaster-ui-season-selector>
		<nx-bundesliga-bundesmaster-ui-club-selector [seasonIdFilter]="clubSelectorSeasonIdFilter" formControlName="clubId"></nx-bundesliga-bundesmaster-ui-club-selector>
	</div>

	<div class="d-flex justify-content-center flex-grow-1 align-items-center" *ngIf="persons.status === 'empty'">No results found</div>

	<div class="items" *ngIf="persons.status !== 'loading' else loading" #itemsContainer>
		<div *ngFor="let person of persons.items" (click)="toggle(person)">
			<nx-bundesliga-bundesmaster-ui-teaser-preview class="player-card" [class.selected]="isSelected(person)">
				<div head class="d-flex flex-row align-items-center small-gap">
					<img class="player-image" [scrollTarget]="itemsContainer" [dflLazyLoad]="person.faceCircleImageUrl" [errorImage]="defaultPlayerImage" [src]="defaultPlayerImage" />
					<div>
						<div *ngIf="!!person.aliasName">{{ person.aliasName }}</div>
						<div class="normal">{{ person.displayName }}</div>
					</div>
				</div>

				<div content *ngIf="!!person.displayedClub" class="d-flex flex-row small-gap align-items-center">
					<nx-bundesliga-bundesmaster-ui-club-logo [size]="40" [clubId]="person.displayedClub.clubId"></nx-bundesliga-bundesmaster-ui-club-logo>
					<div>
						<div>
							<span *ngIf="!!person.displayedClub.shirtNumber">#{{person.displayedClub.shirtNumber}} |</span>
							{{person.displayedClub.name}}
						</div>
						<div *ngIf="!!person.joinedAdditionalClubs">Former: {{person.joinedAdditionalClubs}}</div>
					</div>
				</div>

				<div actions>
					<small>{{person.personId}}</small>
				</div>
			</nx-bundesliga-bundesmaster-ui-teaser-preview>
		</div>
	</div>

	<small *ngIf="persons.status !== 'loading'" class="partial-items-hint">{{persons.partial | partialListHint}}</small>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<div class="controls">
		<button mat-flat-button color="primary" (click)="clearSelection()">
			<span>Clear selection</span>
			<mat-icon>clear</mat-icon>
		</button>
		<button mat-flat-button color="primary" (click)="selectAllVisible()">
			<span>Select all visible</span>
			<mat-icon>done</mat-icon>
		</button>
	</div>
	<button mat-flat-button color="primary" (click)="cancel()">
		<span>Cancel</span>
	</button>
	<button mat-flat-button color="primary" (click)="add()">
		<span>Add</span>
	</button>
</mat-dialog-actions>
