import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { Competition, completeCompetitionList } from '@nx-bundesliga/bundesmaster/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-competition-selector',
	standalone: true,
	imports: [CommonModule, CommonsLibModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatSelectModule, StoreModule],
	templateUrl: './bundesmaster-ui-competition-selector.component.html',
	styleUrls: ['./bundesmaster-ui-competition-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => BundesmasterUiCompetitionSelectorComponent)
		}
	]
})
export class BundesmasterUiCompetitionSelectorComponent implements ControlValueAccessor, OnDestroy {
	private readonly destroying = new Subject<void>();
	public readonly touched = new Subject<void>();

	public readonly formControl = new FormControl('');

	@Input()
	public providedValue: keyof Competition = 'id';

	public readonly competitions = completeCompetitionList;

	ngOnDestroy(): void {
		this.destroying.next();
		this.destroying.complete();
	}

	writeValue(value: string): void {
		this.formControl.setValue(value, { emitEvent: false });
	}

	registerOnChange(handler: (input: string) => void): void {
		this.formControl.valueChanges.pipe(takeUntil(this.destroying)).subscribe(handler);
	}

	registerOnTouched(callback: () => void): void {
		this.touched.subscribe(callback);
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this.formControl.disable();
		} else {
			this.formControl.enable();
		}
	}
}
