<!--
<picture>
  <source [srcset]="dark" [media]="query" />
  <img [src]="light"  [alt]="alt"  [attr.loading]="loading" [attr.height]="height" />
</picture>
-->

<ng-container [ngSwitch]="imageType">
	<ng-container *ngSwitchCase="'fixedSizeWithPriorityImage'">
		<img class="light" [ngSrc]="light" [alt]="alt" [height]="height" [width]="width" priority disableOptimizedSrcset />
		<img class="dark" [ngSrc]="dark" [alt]="alt" [height]="height" [width]="width" priority disableOptimizedSrcset />
	</ng-container>

	<ng-container *ngSwitchCase="'fixedSizeImage'">
		<img class="light" [ngSrc]="light" [alt]="alt" [attr.loading]="loadingValue" [height]="height" [width]="width" disableOptimizedSrcset />
		<img class="dark" [ngSrc]="dark" [alt]="alt" [attr.loading]="loadingValue" [height]="height" [width]="width" disableOptimizedSrcset />
	</ng-container>

	<ng-container *ngSwitchCase="'singleSrcImage'">
		<img [src]="light" [alt]="alt" [attr.height]="heightValue" [attr.loading]="loading" />
	</ng-container>

	<ng-container *ngSwitchDefault>
		<img class="light" [src]="light" [alt]="alt" [attr.height]="heightValue" [attr.loading]="loading" />
		<img class="dark" [src]="dark" [alt]="alt" [attr.height]="heightValue" [attr.loading]="loading" />
	</ng-container>
</ng-container>
