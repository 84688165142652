export function getYoutubeIdFromUrl(url: string): string {
	const match = url.match(/v=([0-9a-z_-]{1,20})/i);
	return match ? match['1'] : url;
}

export function getTwitterIdFromUrl(url: string) {
	if (url) {
		const found = url.match(/^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/i);
		return (found && found.length >= 4 && found[3]) || null;
	} else {
		return null;
	}
}

export function getXIdFromUrl(url: string) {
	if (url) {
		const found = url.match(/^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/i);
		return (found && found.length >= 4 && found[3]) || null;
	} else {
		return null;
	}
}

export function getFacebookIdFromUrl(url: string): string {
	const match = url.match(/(?:https?:\/\/www\.)?facebook\.com\S*?\/post(s)\/(\w*)\/?/i);
	return match ? match['2'] : url;
}

export function getInstagramIdFromUrl(url: string): string {
	const match = url.match(/(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/i);
	return match ? match['1'] : url;
}

export function getApesterIdFromUrl(url: string): string {
	const match = url.match(/(?:https?:\/\/)?renderer\.apester\.com\S*?\/v2\/(\w+)\/?/i);
	return match ? match['1'] : url;
}
