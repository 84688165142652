import { Component, Input } from '@angular/core';
import { DflLoaderTeaserLayouts } from '../dfl-loader-sharebar/dfl-loader-sharebar.component';

@Component({
	selector: 'dfl-loader-article',
	templateUrl: './dfl-loader-article.component.html',
	styleUrls: ['./dfl-loader-article.component.scss']
})
export class DflLoaderArticleComponent {
	@Input() webview = false;
	@Input() migrated = false;
	@Input() layout: DflLoaderTeaserLayouts = 'article';
	constructor() {}
}
