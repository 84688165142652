import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { BundesmasterMatch, BundesmasterMatchApiService, PipesModule, TaggingDialogInput, TaggingDialogResult, emptyBundesmasterDataCollection } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiClubLogoComponent } from '@nx-bundesliga/bundesmaster/ui/club-card';
import { BundesmasterUiCompetitionSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/competition-selector';
import { BundesmasterUiSeasonSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/season-selector';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { Subject, debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-dialog-select-matches',
	standalone: true,
	imports: [CommonModule, MatButtonModule, FormsModule, MatListModule, MatDialogModule, MatProgressSpinnerModule, MatInputModule, CommonsLibModule, PipesModule, ReactiveFormsModule, MatSelectModule, BundesmasterUiSeasonSelectorComponent, BundesmasterUiCompetitionSelectorComponent, BundesmasterUiClubLogoComponent],
	templateUrl: './bundesmaster-ui-dialog-select-matches.component.html',
	styleUrls: ['./bundesmaster-ui-dialog-select-matches.component.scss']
})
export class BundesmasterUiDialogSelectMatchesComponent implements OnInit, OnDestroy {
	@ViewChild('select', { static: true }) select!: TemplateRef<MatSelectionList>;

	private destroying$ = new Subject<void>();

	public selected = new Set<string>();
	public matches = emptyBundesmasterDataCollection<BundesmasterMatch>('loading');

	public readonly searchFormGroup = new FormGroup({
		query: new FormControl(''),
		competition: new FormControl(''),
		season: new FormControl('')
	});

	constructor(public readonly dialogRef: MatDialogRef<BundesmasterUiDialogSelectMatchesComponent, TaggingDialogResult>, @Inject(MAT_DIALOG_DATA) public readonly data: TaggingDialogInput, service: BundesmasterMatchApiService) {
		this.searchFormGroup.valueChanges
			.pipe(
				takeUntil(this.destroying$),
				distinctUntilChanged(),
				debounceTime(500, undefined),
				startWith({ query: '', competition: '', season: '' }),
				switchMap(({ competition, query, season }) => service.getMatches({ competitionId: competition, query, seasonId: season }))
			)
			.subscribe((matches) => (this.matches = matches));
	}

	selectionChange(event: MatSelectionListChange): void {
		event.options.forEach((option) => (option.selected ? this.selected.add(option.value) : this.selected.delete(option.value)));
	}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	ngOnInit() {
		this.selected = new Set(this.data?.selected ?? []);
	}

	add() {
		this.dialogRef.close([...this.selected]);
	}

	cancel() {
		this.dialogRef.close(null);
	}
}
