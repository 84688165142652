import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { BUNDESLIGA_SEASONS, BundesmasterPerson, BundesmasterPersonApiService, BundesmasterPersonAssociatedClub, PipesModule, TaggingDialogInput, TaggingDialogResult, emptyBundesmasterDataCollection } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiClubLogoComponent } from '@nx-bundesliga/bundesmaster/ui/club-card';
import { BundesmasterUiClubSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/club-selector';
import { BundesmasterUiSeasonSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/season-selector';
import { BundesmasterUiTeaserPreviewComponent } from '@nx-bundesliga/bundesmaster/ui/teaser-preview';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { Subject, debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap } from 'rxjs';

interface PersonDisplayModel extends BundesmasterPerson {
	readonly displayedClub?: BundesmasterPersonAssociatedClub;
	readonly joinedAdditionalClubs: string;
	readonly faceCircleImageUrl: string;
}

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-dialog-select-persons',
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		FormsModule,
		BundesmasterUiTeaserPreviewComponent,
		MatDialogModule,
		StoreModule,
		MatProgressSpinnerModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatInputModule,
		MatIconModule,
		CommonsLibModule,
		PipesModule,
		DflNgxLazyLoadModule,
		BundesmasterUiSeasonSelectorComponent,
		BundesmasterUiClubSelectorComponent,
		BundesmasterUiClubLogoComponent
	],
	templateUrl: './bundesmaster-ui-dialog-select-persons.component.html',
	styleUrls: ['./bundesmaster-ui-dialog-select-persons.component.scss']
})
export class BundesmasterUiDialogSelectPersonsComponent implements OnInit, OnDestroy {
	private readonly destroying$ = new Subject<void>();
	public readonly defaultPlayerImage = '/assets/placeholder/player-circle-default.png';

	public persons = emptyBundesmasterDataCollection<PersonDisplayModel>('loading');

	public selectedPersons = new Set<string>();

	public clubSelectorSeasonIdFilter?: string;

	public form = new FormGroup({
		query: new FormControl(''),
		clubId: new FormControl(''),
		seasonId: new FormControl('')
	});

	constructor(public readonly dialogRef: MatDialogRef<BundesmasterUiDialogSelectPersonsComponent, TaggingDialogResult>, @Inject(MAT_DIALOG_DATA) public readonly data: TaggingDialogInput, private readonly personService: BundesmasterPersonApiService) {
		this.form.valueChanges
			.pipe(
				tap((s) => (this.clubSelectorSeasonIdFilter = s.seasonId)),
				takeUntil(this.destroying$),
				startWith({ query: '', clubId: '', seasonId: '' }),
				debounceTime(500),
				distinctUntilChanged(),
				switchMap(({ query, clubId, seasonId }) =>
					personService.getPersons({ query, clubId, seasonId }).pipe(
						map((response) => ({
							...response,
							items: response.items.map((person) => this.buildPersonDisplayModel(person, seasonId))
						}))
					)
				)
			)
			.subscribe((persons) => (this.persons = persons));
	}

	buildPersonDisplayModel(person: BundesmasterPerson, seasonId: string): PersonDisplayModel {
		const noSeasonId = seasonId.length === 0;
		const displayedClub = noSeasonId ? person.mostRecentClub : (person.clubsBySeason[seasonId] ?? [])[0];

		const joinedAdditionalClubs = (noSeasonId ? person.allClubs : person.clubsBySeason[seasonId] ?? [])
			.filter(({ clubId }) => displayedClub?.clubId !== clubId)
			.map(({ threeLetterCode }) => threeLetterCode)
			.join(', ');

		const faceCircleImageUrl = this.personService.buildCircleImageUrl(person, noSeasonId ? BUNDESLIGA_SEASONS.CURRENT : seasonId, displayedClub);

		return { ...person, displayedClub, joinedAdditionalClubs, faceCircleImageUrl };
	}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	ngOnInit(): void {
		this.selectedPersons = new Set(this.data?.selected ?? []);
	}

	toggle({ personId }: BundesmasterPerson) {
		if (this.selectedPersons.has(personId)) {
			this.selectedPersons.delete(personId);
		} else {
			this.selectedPersons.add(personId);
		}
	}

	isSelected({ personId }: BundesmasterPerson): boolean {
		return this.selectedPersons.has(personId);
	}

	add() {
		this.dialogRef.close([...this.selectedPersons]);
	}

	cancel() {
		this.dialogRef.close(null);
	}

	clearSelection(): void {
		this.selectedPersons.clear();
	}

	selectAllVisible(): void {
		this.persons.items.forEach(({ personId }) => this.selectedPersons.add(personId));
	}
}
