import { Rect } from './rect';

/**
 *
 * @param {HTMLImageElement | HTMLDivElement} element
 * @param {string} cssClassName
 */
export function removeCssClassName(element: HTMLImageElement | HTMLDivElement, cssClassName: string) {
	element.className = element.className.replace(cssClassName, '');
}

/**
 *
 * @param {HTMLImageElement | HTMLDivElement} element
 * @param {string} cssClassName
 */
export function addCssClassName(element: HTMLElement | HTMLImageElement | HTMLDivElement, cssClassName: string) {
	if (!element.className.includes(cssClassName)) {
		element.className += ` ${cssClassName}`;
	}
}

/**
 *
 * @param {HTMLImageElement | HTMLDivElement} element
 * @param {string} cssClassName
 * @returns {boolean}
 */
export function hasCssClassName(element: HTMLElement | HTMLImageElement | HTMLDivElement, cssClassName: string) {
	return element.className && element.className.includes(cssClassName);
}

/**
 *
 * @returns {boolean}
 */
export function isWindowDefined() {
	return typeof window !== 'undefined';
}

/**
 *
 * @param {HTMLElement} element
 * @param {number} threshold
 * @param {Window} _window
 * @param {HTMLElement} scrollContainer
 * @returns {boolean}
 */
export function isVisible(element: HTMLElement, threshold = 0, _window: Window, scrollContainer?: HTMLElement) {
	const elementBounds = Rect.fromElement(element);
	if (elementBounds === Rect.empty) {
		return false;
	}
	const windowBounds = Rect.fromWindow(_window);
	elementBounds.inflate(threshold);

	if (scrollContainer) {
		const scrollContainerBounds = Rect.fromElement(scrollContainer);
		const intersection = scrollContainerBounds.getIntersectionWith(windowBounds);
		return elementBounds.intersectsWith(intersection);
	} else {
		return elementBounds.intersectsWith(windowBounds);
	}
}
