<nx-bundesliga-bundesmaster-ui-loading-bar [loading]="loading" [text]="false"></nx-bundesliga-bundesmaster-ui-loading-bar>
<nx-bundesliga-bundesmaster-ui-loading-bar-text *ngIf="initialLoad"></nx-bundesliga-bundesmaster-ui-loading-bar-text>

<ng-container *ngIf="(assets$ | async) as assets">
	<div class="shadow">
		<mat-expansion-panel [expanded]="true">
			<mat-expansion-panel-header>Search</mat-expansion-panel-header>
			<bundesmaster-ui-search-bar [enableFilter]="true" [enableMotifFilter]="true" [enableLlmTagFilter]="true" [query]="query$ | async" (queryChanged)="onSearchQueryChanged($event)" [selectFilter]="selectFilter"></bundesmaster-ui-search-bar>
		</mat-expansion-panel>

		<nx-bundesliga-bundesmaster-ui-mat-paginator-goto
			class="inline"
			(page)="onPageinationChanged($event)"
			(sort)="onSortChanged($event)"
			[length]="assets.total"
			[pageIndex]="page"
			[pageSize]="size"
			[pageSizeOptions]="[24, 48, 72, 96]"
			[sort]="sort"
			[order]="order"
			[showFirstLastButtons]="true"
			[sortOptions]="articleSortOptions"></nx-bundesliga-bundesmaster-ui-mat-paginator-goto>
	</div>

	<div class="items">
		<nx-bundesliga-bundesmaster-ui-loading-bar-text *ngIf="!assets.items || assets.items.length === 0" message="No assets found matching your query 🥺"></nx-bundesliga-bundesmaster-ui-loading-bar-text>

		<a *ngFor="let item of assets?.items" [routerLink]="['/media/asset/' + item.id]">
			<nx-bundesliga-bundesmaster-ui-teaser-preview (click)="$event.preventDefault(); $event.stopPropagation(); assetClicked.emit(item)" [title]="item?.title!">
				<img loading="lazy" *ngIf="item?.media_type === 'image'" [src]="item?.file | assetService:160" alt="" teaser />
				<span head>{{ item?.title! | truncate: [60] }}</span>
				<div actions>
					<div class="property">
						<div class="icon"><mat-icon inline="true">{{item?.media_type}}</mat-icon></div>
						<strong>{{item?.file_size | fileSize}}</strong>
					</div>
					<div class="property">
						<div class="icon"><mat-icon inline="true">{{item?.media_type}}</mat-icon></div>
						<strong>{{item?.mime_type}}</strong>
					</div>
					<div class="property" *ngIf="item?.media_type === 'image'">
						<div class="icon"><mat-icon inline="true">{{item?.media_type}}</mat-icon></div>
						<strong>{{item?.image?.width}} x {{item?.image?.height}}</strong>
					</div>
					<div class="property">
						<div class="icon"><mat-icon inline="true">calendar_month</mat-icon></div>
						{{ item?.created_date }}
					</div>
				</div>
			</nx-bundesliga-bundesmaster-ui-teaser-preview>
		</a>
	</div>

	<div class="shadow bottom">
		<nx-bundesliga-bundesmaster-ui-mat-paginator-goto
			(page)="onPageinationChanged($event)"
			(sort)="onSortChanged($event)"
			[length]="assets.total"
			[pageIndex]="page"
			[pageSize]="size"
			[pageSizeOptions]="[24, 48, 72, 96]"
			[sort]="sort"
			[order]="order"
			[showFirstLastButtons]="true"
			[sortOptions]="articleSortOptions"></nx-bundesliga-bundesmaster-ui-mat-paginator-goto>
	</div>
</ng-container>
