<ng-container *ngIf="playtime && playtime.minute >= 0">
	<div class="wrapper" [ngClass]="{'large': layout === 'large', 'is-paused': !live}">
		<div class="badge-wrapper">
			<ng-container [ngSwitch]="true">
				<ng-container *ngSwitchCase="live !== true && layout !== 'xsmall' && matchStatus === 'HALF'">
					<div class="badge">{{ 'LIVETICKER.end_firstHalf' | translate }}</div>
				</ng-container>
				<ng-container *ngSwitchCase="live !== true && layout === 'xsmall' && matchStatus === 'HALF'">
					<div class="badge">{{ 'LIVETICKER.end_firstHalf_small' | translate }}</div>
				</ng-container>
				<ng-container *ngSwitchCase="live !== true && layout !== 'xsmall' && (matchStatus === 'HALF_EXTRA') ">
					<div class="badge">{{ 'LIVETICKER.end_firstHalfExtra' | translate }}</div>
				</ng-container>
				<ng-container *ngSwitchCase="live !== true && layout === 'xsmall' && (matchStatus === 'HALF_EXTRA') ">
					<div class="badge">{{ 'LIVETICKER.end_firstHalfExtra_small' | translate }}</div>
				</ng-container>
				<ng-container *ngSwitchCase="live === true && layout !== 'xsmall' && (matchStatus === 'PRE_PENALTY' || matchStatus === 'PENALTY') ">
					<div class="badge">{{ 'LIVETICKER.start_penalty' | translate }}</div>
				</ng-container>
				<ng-container *ngSwitchCase="live === true && layout === 'xsmall' && (matchStatus === 'PRE_PENALTY' || matchStatus === 'PENALTY') ">
					<div class="badge">{{ 'LIVETICKER.start_penalty_small' | translate }}</div>
				</ng-container>
				<ng-container *ngSwitchCase="live === true && matchStatus !== 'PRE_PENALTY' && matchStatus !== 'PENALTY' ">
					<div class="badge">LIVE</div>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="live && (layout === 'xsmall' ); then animation"></ng-container>
		</div>
		<ng-container *ngIf="live && layout !== 'xsmall' && playtime && playtime.minute >= 0 && matchStatus !== 'PRE_PENALTY' && matchStatus !== 'PENALTY' ">
			<span class="playtime">{{ playtime.minute }}'</span>
			<span class="playtime injury" *ngIf="layout === 'large' && playtime.injuryTime > 0">+{{ playtime.injuryTime }}</span>
		</ng-container>
	</div>
	<ng-container *ngIf="live && (layout === 'large' || layout === 'regular') ; then animation"></ng-container>
	<!-- Knightrider Animation Template -->
	<ng-template #animation>
		<div class="animation" [ngClass]="{'large': layout === 'large'}">
			<div class="block" id="block1"></div>
			<div class="block" id="block2"></div>
			<div class="block" id="block3"></div>
			<div class="block" id="block4"></div>
			<div class="block" id="block5"></div>
			<div class="block" id="block6"></div>
		</div>
	</ng-template>
</ng-container>
