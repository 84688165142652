import { CommonModule } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule, ProgressBarMode } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { AssetServicePipe, BundesmasterContentApiService, BundesmasterEnvironment, CORE_ENVIRONMENT, PipesModule } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterMediaLibraryUploadItem } from '@nx-bundesliga/bundesmaster/core';
import { EMPTY, concatMap, delay, expand, from, map, take, takeLast, tap } from 'rxjs';
import { getStageDomain } from '@nx-bundesliga/shared/util/functions';
import { BundesmasterUiBoundingBoxOverlayComponent } from '@nx-bundesliga/bundesmaster/ui/bounding-box-overlay';
@Component({
	selector: 'bundesmaster-ui-upload-item',
	standalone: true,
	templateUrl: './bundesmaster-upload-item.component.html',
	styleUrls: ['./bundesmaster-upload-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, MatCardModule, MatProgressSpinnerModule, MatProgressBarModule, MatButtonModule, RouterModule, MatChipsModule, PipesModule, BundesmasterUiBoundingBoxOverlayComponent]
})
export class BundesmasterUiUploadItemComponent implements OnInit {
	@Input() closeable = false;

	private account?: AccountInfo;
	@Input() file?: File;
	@Output() uploaded: EventEmitter<BundesmasterMediaLibraryUploadItem> = new EventEmitter<BundesmasterMediaLibraryUploadItem>();
	public imageLoading = true;
	public ingestId?: string;
	public key?: string;
	public status = 'WAITING';
	public progress = 0;
	public percentage = 0;
	public progressValue = 100;
	public progressMode: ProgressBarMode = 'indeterminate';
	public imageBaseUrl: string;
	public uploadResult?: BundesmasterMediaLibraryUploadItem;
	public imageSrc?: string;

	constructor(@Inject(CORE_ENVIRONMENT) environment: BundesmasterEnvironment, private api: BundesmasterContentApiService, private http: HttpClient, private authService: MsalService, private cdr: ChangeDetectorRef, private assetService: AssetServicePipe) {
		this.imageBaseUrl = `https://assets.${getStageDomain(environment.stage)}`;
		this.account = this.authService.instance.getActiveAccount() || undefined;
	}

	ngOnInit(): void {
		if (this.file) {
			from([this.file])
				.pipe(
					tap(() => (this.status = 'REQUESTING_UPLOAD_URL')),
					tap(() => this.cdr.detectChanges()),
					// request upload url
					concatMap((file) => this.api.startMediaUpload(file.name, file.type, this.account?.username).pipe(map((response) => ({ response, file })))),
					tap(({ response }) => {
						this.status = 'UPLOADING';
						this.ingestId = response.id;
						this.key = response.key;
						this.cdr.detectChanges();
					}),

					// put file
					concatMap(({ response, file }) => {
						const headers = new HttpHeaders().append('Content-Type', file.type);
						return this.http.put<null>(response.uploadURL, file, { reportProgress: true, observe: 'events', headers }).pipe(
							tap((event) => {
								this.#getEventMessage(event);
							}),
							map(() => ({ id: response.id, name: file.name })),
							delay(5000),

							// poll for ingest status
							concatMap((response) => {
								return this.api.getMediaUploadStatus(response.id).pipe(
									delay(1000),
									expand((res, index) => {
										this.status = res.status;
										this.uploadResult = res;
										this.cdr.detectChanges();

										if (res.status !== 'DONE' && res.status !== 'FAILED' && index <= 30) {
											return this.api.getMediaUploadStatus(response.id).pipe(delay(index * 100 + 1000));
										}

										return EMPTY;
									}),
									takeLast(1)
								);
							}),
							take(1)
						);
					})
				)
				.subscribe({
					next: (result: BundesmasterMediaLibraryUploadItem) => {
						console.log('upload succeeded', result);
						this.status = result.status;
						this.uploadResult = result;
						this.imageSrc = this.getImageSrc();
						this.uploaded.emit(this.uploadResult);
						this.cdr.detectChanges();
					},
					error: (error) => {
						this.uploadResult = undefined;
						console.log('upload failed', error);
						this.status = 'FAILED';
						this.progressMode = 'determinate';
						this.cdr.detectChanges();
					},
					complete: () => {
						this.progressMode = this.status === 'DONE' || this.status === 'FAILED' ? 'determinate' : 'indeterminate';
						this.cdr.detectChanges();
					}
				});
		}
	}

	public getImageSrc(): string {
		console.log('getImageSrc', this.imageLoading);
		const imageSrc = this.assetService.transform(`${this.imageBaseUrl}/${this.uploadResult?.path}`, 360);
		if (!this.imageLoading) {
			return imageSrc;
		}
		const timestamp = new Date().getTime();
		const url = new URL(imageSrc);
		url.searchParams.append('t', timestamp.toString(10));
		return url.toString();
	}

	#getEventMessage(event: HttpEvent<null>) {
		switch (event.type) {
			case HttpEventType.UploadProgress: {
				this.progress = event.loaded;
				this.percentage = (this.file && Math.round((100 * this.progress) / this.file.size)) || 0;
				break;
			}

			case HttpEventType.Response:
				this.status = 'PROCESSING';
				break;
		}

		this.cdr.detectChanges();
	}
}
