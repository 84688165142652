import { Pipe, PipeTransform } from '@angular/core';

/*
 * Strips HTM
 * Takes an input parameter HTML.
 * Usage:
 *   content | striphtml
 * Example:
 *   <p [innerHTML]="content | striphtml"></p>
 */
@Pipe({
	name: 'striphtml'
})
export class StripHtmlPipe implements PipeTransform {
	transform(value: string, keepSafeTags = false, allowedAnchorTags: 'all' | 'external' | 'none' = 'none'): any {
		if (value === null || value === '') {
			return '';
		} else {
			if (keepSafeTags) {
				if (allowedAnchorTags === 'none') {
					return value.replace(/<(?!((?:\/\s*)?(?:(br|p|b|u|o|i|ul|li|strong)[ >]))).*?>/gim, '');
				}

				if (allowedAnchorTags === 'all' || allowedAnchorTags === 'external') {
					let retValue = value.replace(/<(?!((?:\/\s*)?(?:(a|br|p|b|u|o|i|ul|li|strong)[ >]))).*?>/gim, '');
					if (allowedAnchorTags === 'external') {
						retValue = retValue.replace(/(<a.href="https?:\/\/\w*.bundesliga(|\-dev|\-appr)"*[^>]+>)([^<]+)(<\/a>)/gim, function (match, $1, $2, $3) {
							return $3;
						});
					}
					return retValue;
				}
			} else {
				return value.replace(/<(?:.|\n)*?>/gm, '');
			}
		}
	}
}
