/**
 *
 * @param stage
 * @param hasIntegration
 * @returns
 */
export function getStageSuffix(stage: string, hasIntegration = true): 'dev' | 'appr' | '' {
	if (stage === 'development' || stage === 'dev') {
		return 'dev';
	} else if (stage === 'integration' || stage === 'int') {
		return hasIntegration ? 'appr' : 'dev';
	}

	return '';
}

export function getStageDomain(stage: string, hasIntegration = true) {
	const suffix = getStageSuffix(stage, hasIntegration);
	return 'bundesliga' + (suffix ? `-${suffix}` : '') + '.com';
}
