import { Pipe, PipeTransform } from '@angular/core';

type PlayerImageSizes = 'PORTRAIT_XXS' | 'PORTRAIT_XS' | 'PORTRAIT_S' | 'PORTRAIT_M' | 'PORTRAIT_L' | 'FACE_CIRCLE' | 'BODY';

@Pipe({
	name: 'playerImage'
})
export class PlayerImagePipe implements PipeTransform {
	transform(value: string, size: PlayerImageSizes = 'PORTRAIT_S'): any {
		const sizeExtension = {
			PORTRAIT_XXS: '-50x50.png',
			PORTRAIT_XS: '-100x100.png',
			PORTRAIT_S: '-150x150.png',
			PORTRAIT_M: '-200x200.png',
			PORTRAIT_L: '.png',
			FACE_CIRCLE: '-circle.png',
			BODY: '-body.png',
			DEPR_FACE_CIRCLE: '-circle-192x192.png'
		};
		// return original value if preconditions are not met
		if (!size || !Object.keys(sizeExtension).includes(size) || !value) {
			return value;
		}
		let extension = '.png'; // we ignore the sizes of images now because it's not needed anymore @todo remove the complete pipe
		// use deprecated circle image for older seasons
		if (size === 'FACE_CIRCLE' && (value.includes('-dfl-sea-0001k3') || value.includes('-dfl-sea-0001k2'))) {
			extension = sizeExtension['DEPR_FACE_CIRCLE'];
		} else if (size === 'FACE_CIRCLE') {
			extension = sizeExtension['FACE_CIRCLE'];
		} else if (size === 'BODY') {
			extension = sizeExtension['BODY'];
		}

		// remove any present affixes and clean inputvalue
		const re = new RegExp(Object.values(sizeExtension).join('|'), 'gi');
		value = value.replace(re, '.png');
		// return playerImage
		return value.replace('.png', extension);
	}
}
