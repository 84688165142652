<div [formGroup]="form">
	<!-- Competitions -->
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Competitions</mat-label>
		<mat-select formControlName="competitions" multiple>
			<mat-select-trigger>
				<mat-chip-listbox #competitionsChipList>
					<mat-chip-row *ngFor="let competition of form.controls['competitions'].value" [value]="competition" (removed)="removeTag('competitions', competition)" [title]="competition" [disabled]="form.disabled">
						{{competition | resolveCompetitionId | async}}
						<button matChipRemove>
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-chip-row>
				</mat-chip-listbox>
			</mat-select-trigger>
			<mat-option *ngFor="let competition of competitions" [value]="competition.id">{{ competition.name }}</mat-option>
		</mat-select>
		<button mat-icon-button matSuffix [disabled]="form.disabled" (click)="addFilterByDialog('competitions', $event)" matTooltip="Add a COMPETITION filter">
			<mat-icon>stars</mat-icon>
		</button>
	</mat-form-field>

	<!-- Seasons -->
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Seasons</mat-label>
		<mat-select formControlName="seasons" multiple>
			<mat-select-trigger>
				<mat-chip-listbox #seasonsChipList>
					<mat-chip-row *ngFor="let season of form.controls['seasons'].value" [value]="season" (removed)="removeTag('seasons', season)" [title]="season" [disabled]="form.disabled">
						{{season | resolveSeasonId | async}}
						<button matChipRemove>
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-chip-row>
				</mat-chip-listbox>
			</mat-select-trigger>
			<mat-option *ngFor="let season of (seasons$ | async)" [value]="season.seasonId">{{ season.season }}</mat-option>
		</mat-select>
		<button mat-icon-button matSuffix [disabled]="form.disabled" (click)="addFilterByDialog('seasons', $event)" matTooltip="Add a SEASON filter">
			<mat-icon>event_available</mat-icon>
		</button>
	</mat-form-field>

	<!-- Matchdays -->
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Matchdays</mat-label>
		<mat-chip-grid #matchdayChipList formControlName="matchdays">
			<mat-chip-row *ngFor="let matchday of form.controls['matchdays'].value" [value]="matchday" (removed)="removeTag('matchdays', matchday)" [title]="matchday" [disabled]="form.disabled">
				{{(matchday | resolveMatchDayId | async)}}
				<button matChipRemove>
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input [formControl]="matchdaysInputFormControl" placeholder="Add Matchday.." #matchdayInput [matAutocomplete]="matchdayAutoComplete" [matChipInputFor]="matchdayChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
		</mat-chip-grid>

		<div matSuffix class="d-flex flex-row align-items-center">
			<mat-spinner *ngIf="matchdays.status === 'loading'" [diameter]="15"></mat-spinner>
			<button mat-icon-button matSuffix [disabled]="form.disabled" (click)="addFilterByDialog('matchdays', $event)" matTooltip="Add a MATCHDAY filter">
				<mat-icon>event_available</mat-icon>
			</button>
		</div>

		<mat-autocomplete #matchdayAutoComplete="matAutocomplete" (optionSelected)="addTag('matchdays', $event.option.value, matchdaysInputFormControl); matchdayInput.value = '';">
			<mat-option *ngFor="let matchday of matchdays.items" [value]="matchday.matchdayId">
				<div class="d-flex flex-column option-padding">
					<div>{{matchday.matchdayLabel}}</div>
					<small>{{matchday.season}} | {{matchday.matchdayId}}</small>
				</div>
			</mat-option>
			<div class="d-flex flex-row justify-content-center"><small>{{matchdays.partial | partialListHint}}</small></div>
		</mat-autocomplete>
	</mat-form-field>

	<!-- Matches -->
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Matches</mat-label>
		<mat-chip-grid #matchesChipList formControlName="matches">
			<mat-chip-row *ngFor="let match of form.controls['matches'].value" [value]="match" (removed)="removeTag('matches', match)" [title]="match | resolveMatchId:'long' | async" [disabled]="form.disabled">
				{{(match | resolveMatchId | async)}}
				<button matChipRemove>
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input [formControl]="matchesInputFormControl" placeholder="Add Match..." #matchInput [matAutocomplete]="matchAutoComplete" [matChipInputFor]="matchesChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
		</mat-chip-grid>
		<div matSuffix class="d-flex flex-row align-items-center">
			<mat-spinner *ngIf="matches.status === 'loading'" [diameter]="15"></mat-spinner>
			<button mat-icon-button [disabled]="form.disabled" (click)="addFilterByDialog('matches', $event)" matTooltip="Add a MATCH filter">
				<mat-icon>event_available</mat-icon>
			</button>
		</div>

		<mat-autocomplete #matchAutoComplete="matAutocomplete" (optionSelected)="addTag('matches', $event.option.value, matchesInputFormControl); matchInput.value = '';">
			<mat-option *ngFor="let match of matches.items" [value]="match.matchId">
				<div class="d-flex flex-column option-padding">
					<div>MD{{match.matchday}}: {{ match.home.threeLetterCode }} vs. {{ match.guest.threeLetterCode }}</div>
					<small>{{match.season}} | {{match.competitionName}} | {{match.matchId}}</small>
				</div>
			</mat-option>
			<div class="d-flex flex-row justify-content-center"><small>{{matches.partial | partialListHint}}</small></div>
		</mat-autocomplete>
		<mat-hint *ngIf="canBulkTagMatches" align="end" (click)="bulkTagMatches()"><a class="tag-matches-link">Add tags for matches based on seasons, matchdays and competitions</a></mat-hint>
	</mat-form-field>

	<!-- Clubs -->
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Clubs</mat-label>
		<mat-chip-grid #clubsChipList formControlName="clubs">
			<mat-chip-row *ngFor="let club of form.controls['clubs'].value" [value]="club" (removed)="removeTag('clubs', club)" [title]="club" [disabled]="form.disabled">
				<nx-bundesliga-bundesmaster-ui-club-logo [size]="28" matChipAvatar [clubId]="club"></nx-bundesliga-bundesmaster-ui-club-logo>
				{{club | resolveClubId | async}}
				<button matChipRemove>
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input [formControl]="clubsInputFormControl" placeholder="Add Club.." #clubInput [matAutocomplete]="clubAutoComplete" [matChipInputFor]="clubsChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
		</mat-chip-grid>
		<div matSuffix class="d-flex flex-row align-items-center">
			<mat-spinner *ngIf="clubs.status === 'loading'" [diameter]="15"></mat-spinner>
			<button mat-icon-button matSuffix [disabled]="form.disabled" (click)="addFilterByDialog('clubs', $event)" matTooltip="Add a CLUB filter">
				<mat-icon>sports_soccer</mat-icon>
			</button>
		</div>
		<mat-autocomplete #clubAutoComplete="matAutocomplete" (optionSelected)="addTag('clubs', $event.option.value, clubsInputFormControl); clubInput.value = '';">
			<mat-option *ngFor="let club of clubs.items" [value]="club.clubId">
				<div class="d-flex flex-row align-items-center option-padding gap">
					<nx-bundesliga-bundesmaster-ui-club-logo [size]="48" [clubId]="club.clubId"></nx-bundesliga-bundesmaster-ui-club-logo>
					<div class="d-flex flex-column">
						<div>{{club.displayName}}</div>
						<small>{{ club.threeLetterCode }} | {{club.clubId}}</small>
					</div>
				</div>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

	<!-- Players -->
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Players</mat-label>
		<mat-chip-grid #playersChipList formControlName="players">
			<mat-chip-row *ngFor="let player of form.controls['players'].value" [value]="player" (removed)="removeTag('players', player)" [title]="player" [disabled]="form.disabled">
				{{player | resolvePersonId | async}}
				<button matChipRemove>
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input [formControl]="personsInputFormControl" placeholder="Add Player.." #playerInput [matAutocomplete]="personsAutoComplete" [matChipInputFor]="playersChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
		</mat-chip-grid>
		<div matSuffix class="d-flex flex-row align-items-center">
			<mat-spinner *ngIf="persons.status === 'loading'" [diameter]="15"></mat-spinner>
			<button mat-icon-button matSuffix [disabled]="form.disabled" (click)="addFilterByDialog('players', $event)" matTooltip="Add a PERSON filter">
				<mat-icon>group</mat-icon>
			</button>
		</div>
		<mat-autocomplete #personsScrolltarget #personsAutoComplete="matAutocomplete" (closed)="personSelectorClosed()" (opened)="personSelectorOpenend(personsScrolltarget)" (optionSelected)="addTag('players', $event.option.value, personsInputFormControl); playerInput.value = '';">
			<mat-option *ngFor="let person of persons.items" [value]="person.personId">
				<div class="d-flex flex-row align-items-center option-padding gap">
					<img class="player-image" [dflLazyLoad]="getPersonFaceImageUrl(person)" [scrollObservable]="personsScrollSubject" defaultImage="/assets/placeholder/player-circle-default.png" errorImage="/assets/placeholder/player-circle-default.png" width="48" height="48" />

					<div class="d-flex flex-column">
						<div *ngIf="!person.aliasName">{{person.displayName}}</div>
						<div *ngIf="!!person.aliasName">
							{{person.aliasName}}
							<small>({{person.displayName}})</small>
						</div>
						<small>{{person.firstActiveYear}} - {{person.lastActiveYear}} | {{joinClubs(person)}} | {{person.personId}}</small>
					</div>
				</div>
			</mat-option>
			<div class="d-flex flex-row justify-content-center"><small>{{persons.partial | partialListHint}}</small></div>
		</mat-autocomplete>
	</mat-form-field>

	<!-- Tags -->
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Tags</mat-label>
		<mat-chip-grid #tagsChipList formControlName="tags">
			<mat-chip-row *ngFor="let tag of form.controls['tags'].value" [value]="tag" (removed)="removeTag('tags', tag)" [title]="tag" [disabled]="form.disabled">
				{{tag}}
				<button matChipRemove>
					<mat-icon>cancel</mat-icon>
				</button>
			</mat-chip-row>
			<input placeholder="Add Tag.." #tagInput [matAutocomplete]="tagsAutoComplete" [matChipInputFor]="tagsChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag('tags', $event.value);tagInput.value = '';" />
		</mat-chip-grid>
		<mat-autocomplete #tagsAutoComplete="matAutocomplete" (optionSelected)="addTag('tags', $event.option.value); tagInput.value = '';">
			<mat-option *ngFor="let tag of tags" [value]="tag">{{ tag }}</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</div>
