<h2 mat-dialog-title>Select Motif (Model V1)</h2>

<mat-dialog-content class="mat-typography">
	<mat-selection-list (selectionChange)="selectionChange($event)" [ngModel]="selectedMotifs">
		<mat-list-option *ngFor="let motif of possibleMotifs" [value]="motif">{{ motif }}</mat-list-option>
	</mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-flat-button color="primary" (click)="cancel()">
		<span>Cancel</span>
	</button>
	<button mat-flat-button color="primary" (click)="add()">
		<span>Add</span>
	</button>
</mat-dialog-actions>
