import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { additionalCompetitions, competitions } from '../../models/bundesliga.competition.model';

@Pipe({
	name: 'resolveCompetitionId'
})
export class ResolveCompetitionIdPipe implements PipeTransform {
	private readonly knownCompetitions = new Map<string, string>(Object.values({ ...competitions, ...additionalCompetitions }).map(({ id, displayName }) => [id, displayName]));

	transform(competitionId: string): Observable<string> {
		return of(this.knownCompetitions.get(competitionId) ?? competitionId);
	}
}
