<div class="goto">
	<mat-paginator [length]="_length" [pageIndex]="_pageIndex" [pageSize]="_pageSize" [pageSizeOptions]="pageSizeOptions" (page)="paginationChange($event)"></mat-paginator>

	<ng-container *ngIf="!hideSort && sortOptions?.length > 0">
		<div class="selection-container">
			<div class="selection-label">Sort by</div>
			<mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100">
				<mat-select [(ngModel)]="_sort" (selectionChange)="sortChange($event)">
					<mat-option [value]="'_score'">Relevance</mat-option>
					<mat-option *ngFor="let sortName of sortOptions" [value]="sortName">{{sortName}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="selection-container">
			<div class="selection-label">Order</div>
			<mat-form-field appearance="outline" subscriptSizing="dynamic">
				<mat-select [(ngModel)]="_order" (selectionChange)="orderChange($event)">
					<mat-option *ngFor="let orderDirection of ['desc', 'asc']" [value]="orderDirection">{{orderDirection}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</ng-container>

	<div class="selection-container">
		<div class="selection-label">Page</div>
		<mat-form-field appearance="outline" subscriptSizing="dynamic">
			<mat-select [(ngModel)]="_pageIndex" (selectionChange)="goToChange($event)">
				<mat-option *ngFor="let pageNumber of pageNumbers" [value]="pageNumber">{{pageNumber + 1}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</div>
