export const BUNDESLIGA_ROUTES: { [language: string]: { [route: string]: string } } = {
	de: {
		player: 'spieler',
		matchday: 'spieltag',
		table: 'tabelle',
		clubs: 'clubs'
	},
	en: {
		player: 'player',
		matchday: 'matchday',
		table: 'table',
		clubs: 'clubs'
	},
	ar: {
		player: 'player',
		matchday: 'matchday',
		table: 'table',
		clubs: 'clubs'
	},
	jp: {
		player: 'player',
		matchday: 'matchday',
		table: 'table',
		clubs: 'clubs'
	},
	fr: {
		player: 'joueur',
		matchday: 'journee',
		table: 'classement',
		clubs: 'clubs',
		news: 'actus'
	},
	es: {
		player: 'jugador',
		matchday: 'partidos',
		table: 'clasificacion',
		clubs: 'clubes',
		news: 'noticias'
	},
	pt: {
		player: 'jogador',
		matchday: 'rodada',
		table: 'tabela',
		clubs: 'clubes'
	}
};
