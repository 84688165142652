import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { PipesModule } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiArticleTagsModule } from '@nx-bundesliga/bundesmaster/ui/article-tags';
import { BundesmasterUiImageCropperComponent } from '@nx-bundesliga/bundesmaster/ui/image-cropper';
import { BundesmasterUiLoadingBarComponent, BundesmasterUiLoadingBarTextComponent } from '@nx-bundesliga/bundesmaster/ui/loading-bar';
import { BundesmasterUiMatPaginatorGotoComponent } from '@nx-bundesliga/bundesmaster/ui/mat-paginator-goto';
import { BundesmasterUiSearchBarComponent } from '@nx-bundesliga/bundesmaster/ui/search-bar';
import { BundesmasterUiTeaserPreviewComponent } from '@nx-bundesliga/bundesmaster/ui/teaser-preview';
import { BundesmasterUiUploadItemComponent } from '@nx-bundesliga/bundesmaster/ui/upload-item';
import { BundesmasterUiUploadZoneComponent } from '@nx-bundesliga/bundesmaster/ui/upload-zone';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { DflNgxLazyLoadModule } from '@nx-bundesliga/shared/dfl-ngx-lazyload';
import { MediaLibraryOverviewComponent } from './components/overview/media-library-overview.component';
import { DialogAddMediaComponent } from './dialogs/dialog-add-media/dialog-add-media.component';
import { DialogMediaEditorComponent } from './dialogs/dialog-media-editor/dialog-media-editor.component';
import { DialogSelectMediaComponent } from './dialogs/dialog-select-media/dialog-select-media.component';

@NgModule({
	declarations: [MediaLibraryOverviewComponent, DialogAddMediaComponent, DialogMediaEditorComponent, DialogSelectMediaComponent],
	exports: [MediaLibraryOverviewComponent, DialogAddMediaComponent, DialogMediaEditorComponent, DialogSelectMediaComponent],
	providers: [],
	imports: [
		CommonModule,
		CommonsLibModule,
		PipesModule,
		MatIconModule,
		MatButtonModule,
		MatToolbarModule,
		MatPaginatorModule,
		MatExpansionModule,
		BundesmasterUiSearchBarComponent,
		BundesmasterUiTeaserPreviewComponent,
		BundesmasterUiMatPaginatorGotoComponent,
		BundesmasterUiLoadingBarComponent,
		BundesmasterUiLoadingBarTextComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		DflNgxLazyLoadModule,
		CommonsLibModule,
		MatCheckboxModule,
		BundesmasterUiImageCropperComponent,
		MatSelectModule,
		MatTabsModule,
		BundesmasterUiUploadItemComponent,
		BundesmasterUiUploadZoneComponent,
		BundesmasterUiArticleTagsModule,
		MatSnackBarModule,
		RouterLink
	]
})
export class BundesmasterFeatureMediaLibraryModule {}
