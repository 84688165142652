<mat-form-field>
	<mat-label>Club</mat-label>
	<mat-hint *ngIf="hasValue && formControl.value | resolveClubId | async as resolvedClub">
		<ng-container *ngIf="resolvedClub !== formControl.value">
			<nx-bundesliga-bundesmaster-ui-club-logo [size]="12" [clubId]="formControl.value"></nx-bundesliga-bundesmaster-ui-club-logo>
			{{resolvedClub}}
		</ng-container>
	</mat-hint>
	<mat-spinner matSuffix *ngIf="clubs.status === 'loading'" [diameter]="15"></mat-spinner>
	<input matInput type="text" [formControl]="formControl" [matAutocomplete]="auto" />
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
		<mat-option *ngFor="let club of clubs.items" [value]="club.clubId">
			<div class="d-flex flex-row align-items-center option-padding gap">
				<nx-bundesliga-bundesmaster-ui-club-logo [size]="48" [clubId]="club.clubId"></nx-bundesliga-bundesmaster-ui-club-logo>
				<div class="d-flex flex-column">
					<div>{{club.displayName}}</div>
					<small>{{ club.threeLetterCode }} | {{club.clubId}}</small>
				</div>
			</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
