import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ArticleLanguageState, articleLanguageReducer } from './article-language/article-language.reducer';
import * as broadcasterSearch from './broadcaster-search/broadcaster-search.reducer';
import * as competition from './competition/competition.reducer';
import * as language from './language/language.reducer';

export interface State {
	competition: competition.State;
	broadcasterSearch: broadcasterSearch.BroadcasterSearchState;
	language: language.State;
	articleLanguage: ArticleLanguageState;
}

export const reducers: ActionReducerMap<State> = {
	competition: competition.reducer,
	broadcasterSearch: broadcasterSearch.broadcasterSearchReducer,
	language: language.reducer,
	articleLanguage: articleLanguageReducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({ keys: [competition.competitionFeatureKey, language.languageFeatureKey /* matches.matchesFeatureKey */], rehydrate: true })(reducer);
}

export function logger(reducer: any) {
	return function newReducer(state: any, action: any) {
		console.group(action.type);
		const nextState = reducer(state, action);
		console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, state);
		console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
		console.log(`%c next state`, `color: #4CAF50; font-weight: bold`, nextState);
		console.groupEnd();
		return nextState;
	};
}
