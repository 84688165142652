import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';

interface JsonBodyRequestOptions {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	observe?: 'body';
	responseType?: 'json';
}

@Injectable({
	providedIn: 'root'
})
export class BundesligaHttpClientService {
	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) private readonly environment: BundesmasterEnvironment) {}
	public get<T>(path: string, language?: string, options?: any): Observable<T> {
		return this.http.get<T>(`${this.environment.apis.wapp.url}/${path}`, this.getRequestOptions(language, options));
	}

	private getRequestOptions(language?: string, options?: any): JsonBodyRequestOptions {
		const headers = this.getHeaders(language);

		return {
			headers,
			responseType: 'json',
			observe: 'body',
			...options
		};
	}

	private getHeaders(language?: string): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept-Language': language || 'en-EN',
			'x-api-key': this.environment.apis.wapp.key
		});
	}
}
