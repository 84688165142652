<h2 mat-dialog-title>Select Matches</h2>

<ng-template #loading>
	<div class="d-flex align-items-center justify-content-center flex-grow-1">
		<mat-progress-spinner diameter="60" mode="indeterminate"></mat-progress-spinner>
	</div>
</ng-template>

<ng-template #nothingFound>
	<p class="d-flex flex-column align-items-center justify-content-center flex-grow-1">No matches found</p>
</ng-template>

<mat-dialog-content class="mat-typography d-flex flex-column overflow-hidden">
	<div class="d-flex flex-row gap" [formGroup]="searchFormGroup">
		<mat-form-field class="flex-grow-1">
			<mat-label>Try searching for a match by typing 'BVB@FCB', 'schalke@köln 1997' or '1983 MD1' ...</mat-label>
			<input formControlName="query" #searchInput matInput />
		</mat-form-field>

		<nx-bundesliga-bundesmaster-ui-competition-selector formControlName="competition"></nx-bundesliga-bundesmaster-ui-competition-selector>
		<nx-bundesliga-bundesmaster-ui-season-selector formControlName="season"></nx-bundesliga-bundesmaster-ui-season-selector>
	</div>

	<ng-container *ngIf="matches.status !== 'loading'; else loading" class="overflow-auto flex-grow-1">
		<mat-selection-list *ngIf="matches.status !== 'empty'; else nothingFound" (selectionChange)="selectionChange($event)" [ngModel]="selected">
			<mat-list-option *ngFor="let match of matches.items" [value]="match.matchId">
				<small>{{match.season}} / {{match.competitionName}} / Matchday {{match.matchday}}:</small>
				<nx-bundesliga-bundesmaster-ui-club-logo [size]="32" [clubId]="match.home.clubId"></nx-bundesliga-bundesmaster-ui-club-logo>
				{{ match.home.threeLetterCode }} vs.
				<nx-bundesliga-bundesmaster-ui-club-logo [size]="32" [clubId]="match.guest.clubId"></nx-bundesliga-bundesmaster-ui-club-logo>
				{{ match.guest.threeLetterCode }}
				<small>({{match.matchId}})</small>
			</mat-list-option>
		</mat-selection-list>
		<small class="text-center">{{matches.partial | partialListHint}}</small>
	</ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-flat-button color="primary" (click)="cancel()">
		<span>Cancel</span>
	</button>
	<button mat-flat-button color="primary" (click)="add()">
		<span>Add</span>
	</button>
</mat-dialog-actions>
