import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'player-image',
	templateUrl: './player-image.component.html',
	styleUrls: ['./player-image.component.scss']
})
export class PlayerImageComponent implements OnChanges {
	@Input() dimension: number;
	@Input() playerImage = '';
	@Input() fetchPriority: 'auto' | 'high' | 'low' = 'auto';
	@Input() isLoading = false;
	@Input() invertPlaceholder = false;
	@Input() placeHolderImg = '/assets/placeholder/player-portrait-default.svg';
	@Input() language = 'en';
	@Input() competition = 'bundesliga';
	@Input() playerName = '';
	@Input() enabledLinking = false;

	public src: string;
	public placeHolderImgInverted = '/assets/placeholder/player-portrait-stats.svg';

	ngOnChanges() {
		this.setPlayerImage();
	}

	setPlayerImage() {
		if (this.playerImage && this.playerImage !== '') {
			this.src = this.playerImage;
		} else {
			this.src = this.getFallbackImage();
		}
	}

	getFallbackImage(): string {
		if (this.invertPlaceholder === true) {
			return this.placeHolderImgInverted;
		} else {
			return this.placeHolderImg;
		}
	}

	public handleMissingImage(event: Event) {
		(event.target as HTMLImageElement).src = this.getFallbackImage();
	}
}
