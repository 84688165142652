import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, ObservableInput, ReplaySubject, catchError, debounceTime, finalize, map, tap, throwError } from 'rxjs';
import { CORE_ENVIRONMENT } from '../../bundesmaster-core.module';
import { BundesmasterEnvironment } from '../../bundesmaster-environment';
import { BundesmasterBroadcastAdditionList, BundesmasterBroadcastAdditionRequest, BundesmasterBroadcasterListResponse, BundesmasterBroadcasterValidationError } from './contracts';
import { BundesmasterBroadcaster, BundesmasterBroadcasterDeleteResponse, PartialBundesmasterBroadcaster } from './contracts/bundesmaster-broadcaster';

class EpgValidationError implements BundesmasterBroadcasterValidationError {
	constructor(public readonly message: string = 'Request failed') {}
}

class BroadcasterNotFoundError {}

export function isEpgValidationError(error: unknown): error is BundesmasterBroadcasterValidationError {
	return error instanceof EpgValidationError;
}

export function isBroadcasterNotFoundError(error: unknown): boolean {
	return error instanceof BroadcasterNotFoundError;
}

@Injectable({
	providedIn: 'root'
})
export class BundesmasterEpgApiService {
	private readonly baseUrl: string;
	private pendingOperations = new Set<Observable<unknown>>();
	private isLoadingEmitter = new ReplaySubject<boolean>(1);

	public get isLoading() {
		return this.isLoadingEmitter.asObservable().pipe(debounceTime(0));
	}

	constructor(private http: HttpClient, @Inject(CORE_ENVIRONMENT) environment: BundesmasterEnvironment) {
		this.baseUrl = environment.apis.proxy.url;
		//this.baseUrl = 'http://localhost:4987';
	}

	private interceptErrors<T>() {
		return catchError<T, ObservableInput<T>>((error) => {
			if (error instanceof HttpErrorResponse) {
				if (error.status === 400) {
					//const body: Partial<BundesmasterBroadcasterValidationError> = error.error;
					throw new EpgValidationError(error.error);
				}

				if (error.status === 404) {
					throw new BroadcasterNotFoundError();
				}
			}

			throw error;
		});
	}

	private emitLoadingStatus(finishedObservable?: Observable<unknown>): void {
		if (finishedObservable !== undefined) {
			this.pendingOperations.delete(finishedObservable);
		}

		this.isLoadingEmitter.next(this.pendingOperations.size > 0);
	}

	private trackLoadingStatus<T>(observable: Observable<T>): Observable<T> {
		this.pendingOperations.add(observable);

		this.emitLoadingStatus();

		return observable.pipe(
			tap(() => this.emitLoadingStatus(observable)),
			finalize(() => this.emitLoadingStatus(observable))
		);
	}

	public deleteBroadcaster(broadcasterId: number, country: string): Observable<BundesmasterBroadcasterDeleteResponse> {
		if (broadcasterId == null || (country ?? '').trim().length === 0) {
			return throwError(() => new EpgValidationError('Failed to delete broadcaster. No country or broadcasterId provided'));
		}

		return this.trackLoadingStatus(this.http.delete<BundesmasterBroadcasterDeleteResponse>(`${this.baseUrl}/epg/broadcasters/${broadcasterId}/${country}`).pipe(this.interceptErrors()));
	}

	public createBroadcaster(broadcaster: PartialBundesmasterBroadcaster): Observable<BundesmasterBroadcaster> {
		return this.updateBroadcaster(broadcaster);
	}

	public updateBroadcaster(broadcaster: PartialBundesmasterBroadcaster): Observable<BundesmasterBroadcaster> {
		return this.trackLoadingStatus(this.http.post<BundesmasterBroadcaster>(`${this.baseUrl}/epg/broadcasters`, broadcaster).pipe(this.interceptErrors()));
	}

	public getBroadcaster(broadcasterId: string, country: string): Observable<BundesmasterBroadcaster> {
		return this.trackLoadingStatus(this.http.get<BundesmasterBroadcaster>(`${this.baseUrl}/epg/broadcasters/${broadcasterId}/${country}`).pipe(this.interceptErrors()));
	}

	public getBroadcasterList(): Observable<BundesmasterBroadcasterListResponse> {
		return this.trackLoadingStatus(this.http.get<BundesmasterBroadcasterListResponse>(`${this.baseUrl}/epg/broadcasters`));
	}

	public getAdditionsList(): Observable<BundesmasterBroadcastAdditionList> {
		return this.trackLoadingStatus(this.http.get<BundesmasterBroadcastAdditionList>(`${this.baseUrl}/epg/broadcast-additions`));
	}

	public createOrUpdateAddition(addition: BundesmasterBroadcastAdditionRequest): Observable<BundesmasterBroadcastAdditionRequest> {
		return this.trackLoadingStatus(this.http.post<never>(`${this.baseUrl}/epg/broadcast-additions`, addition)).pipe(map(() => addition));
	}

	public deleteAddition(matchId: string): Observable<string> {
		return this.trackLoadingStatus(this.http.delete<never>(`${this.baseUrl}/epg/broadcast-additions/${matchId}`).pipe(map(() => matchId)));
	}
}
