<div class="background is-article">
	<div class="timeline-item">
		<header [ngClass]="{'migratedArticle': migrated}"></header>
		<div class="captionContainer animated-background">
			<div class="caption">
				<div class="background-mask teaser-caption-top-row-1"></div>
				<div class="background-mask teaser-caption-top-row-2"></div>
				<div class="background-mask teaser-caption-top-row-2-right"></div>
				<div class="background-mask teaser-caption-top-row-3"></div>
			</div>
		</div>
		<div class="body headlines animated-background">
			<div class="category">
				<div class="background-mask category-top"></div>
				<div class="background-mask category-bottom"></div>
				<div class="background-mask category-right"></div>
			</div>
			<div class="headline">
				<div class="background-mask headline-top"></div>
			</div>
			<div class="headline">
				<div class="background-mask headline-top"></div>
				<div class="background-mask headline-right-20"></div>
			</div>
			<div class="headline d-md-none">
				<div class="background-mask headline-top"></div>
				<div class="background-mask headline-right-10"></div>
			</div>
			<div class="time-ago">
				<div class="background-mask time-ago-top"></div>
				<div class="background-mask time-ago-right"></div>
				<div class="background-mask time-ago-bottom"></div>
			</div>
			<div class="background-mask spacer-bottom"></div>
		</div>
	</div>
	<dfl-loader-sharebar [layout]="layout" [webview]="webview"></dfl-loader-sharebar>
	<div class="body content">
		<div class="subheadlineContainer animated-background">
			<div class="background-mask subheadline subheadline01"></div>
			<div class="background-mask subheadline subheadline02"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline03"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline04"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline05"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline06"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline07"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline08"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline09"></div>
		</div>

		<div class="redlinkContainer animated-background">
			<div class="background-mask subheadline subheadline01"></div>
			<div class="background-mask subheadline subheadline02"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline03"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline04"></div>
		</div>

		<div class="textContentContainer animated-background">
			<div class="background-mask subheadline subheadline01"></div>
			<div class="background-mask subheadline subheadline02"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline03"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline04"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline05"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline06"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline07"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline08"></div>
			<div class="background-mask subheadline subheadlineDivider"></div>
			<div class="background-mask subheadline subheadline09"></div>
		</div>
	</div>
</div>
