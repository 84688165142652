import { BundesligaCardTypes, BundesligaWhistleTypes, LiveBlogPerson } from '@nx-bundesliga/models';
import { ArticleLanguages, EditorialContentLabels, StatusTypes } from './article.model';
import { Tags } from './tags.model';

export type StoryTypes = 'article_story' | 'match_story';
export const storyTypes: StoryTypes[] = ['article_story', 'match_story'];

export enum SLIDE_TYPES {
	TEXT = 'text',
	VIDEO = 'video',
	QUOTE = 'quote',
	GOAL = 'goal',
	SUB = 'sub',
	CARD = 'card',
	PROGRESS = 'progress',
	LINEUP = 'lineup'
}
export enum SLIDE_CTA_TYPES {
	ARTICLE = 'article',
	CLUB = 'club',
	PLAYER = 'player',
	MATCHDAY = 'matchday',
	MATCH = 'match',
	GAMES = 'games',
	TABLE = 'table',
	VIDEOS = 'videos'
}

export interface SlidePerson extends LiveBlogPerson {
	dflDatalibraryClubId: string;
}

export interface SlideLineupPerson extends SlidePerson {
	dflDatalibraryClubId: string;
	shirtNumber: number;
}

export interface SlideScore {
	home: number;
	away: number;
}

export interface SlideImage {
	url: string;
	copyright: string;
}

export interface SlideVideo {
	vid: string;
	duration: number;
}

export interface SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES;
	targetParams?: {
		clubId?: string;
		personId?: string;
		competitionId?: string;
		seasonId?: string;
		matchdayId?: string;
		matchId?: string;
		articleId?: string;
		tab?: string;
	};
}

export interface SlideCtaArticle extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.ARTICLE;
	targetParams: {
		articleId: string;
	};
}
export interface SlideCtaClub extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.CLUB;
	targetParams: {
		competitionId: string;
		seasonId: string;
		clubId: string;
	};
}
export interface SlideCtaPlayer extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.PLAYER;
	targetParams: {
		competitionId: string;
		seasonId: string;
		clubId: string;
		personId: string;
	};
}
export interface SlideCtaMatchday extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.MATCHDAY;
	targetParams: {
		competitionId: string;
		seasonId: string;
		matchdayId: string;
	};
}
export interface SlideCtaMatch extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.MATCH;
	targetParams: {
		competitionId: string;
		seasonId: string;
		matchdayId: string;
		matchId: string;
		tab: 'liveticker' | 'stats';
	};
}
export interface SlideCtaTable extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.TABLE;
	targetParams: {
		competitionId: string;
		seasonId: string;
	};
}
export interface SlideCtaGames extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.GAMES;
}
export interface SlideCtaVideos extends SlideCtaBase {
	text: string;
	target: SLIDE_CTA_TYPES.VIDEOS;
}
export interface SlideCtaNone {
	text?: '';
	target: '';
	targetParams?: any;
}

export type SlideCta = SlideCtaNone | SlideCtaArticle | SlideCtaClub | SlideCtaPlayer | SlideCtaMatchday | SlideCtaMatch | SlideCtaGames | SlideCtaTable | SlideCtaVideos;

export interface SlidePlaytime {
	minute: number;
	injuryTime?: number;
}

export interface StorySlideBase {
	slideId?: string;
	cta?: SlideCta;
}

export interface MatchStorySlideBase {
	playtime: SlidePlaytime;
}

export interface TextSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.TEXT;
	headline: string;
	text: string;
	image: SlideImage;
}

export interface VideoSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.VIDEO;
	headline?: string;
	text?: string;
	video: SlideVideo;
}

export interface QuoteSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.QUOTE;
	quote: string;
	author: string;
	image: SlideImage;
}

export interface SubSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.SUB;
	dflDatalibraryClubId: string;
	in: SlidePerson;
	out: SlidePerson;
	image: SlideImage;
}

export interface CardSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.CARD;
	cardType: BundesligaCardTypes;
	person: SlidePerson;
	image: SlideImage;
}

export interface ProgressSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.PROGRESS;
	progressType: BundesligaWhistleTypes;
	score: SlideScore;
	image: SlideImage;
}

export interface LineupSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.LINEUP;
	dflDatalibraryClubId: string;
	persons: SlideLineupPerson[];
	image: SlideImage;
}

export interface GoalSlide extends StorySlideBase {
	slideType: SLIDE_TYPES.GOAL;
	goalType: 'goal' | 'ownGoal';
	penalty?: boolean;
	xG?: number;
	distanceToGoal?: number;
	shotSpeed?: number;
	scorer: SlidePerson;
	score: SlideScore;
	image: SlideImage;
}

export interface OpenSearchStoryBase<T> {
	storyType: StoryTypes;
	storyId: string;
	slides: T[];
	title: string;

	// same as Article
	authors: string[];
	tags: Tags;
	live: boolean;
	language: ArticleLanguages;
	status: StatusTypes;
	created_date?: string;
	modified_date?: string;
	editorial_date: string;
	last_publish_date?: string;
	scheduled_date?: string;
	published_by?: string;
	scheduled_by?: string;
	approved_by?: string;
	process?: 'translating' | null;
	localizedFromStoryId?: string;
	localizedVariants?: {
		storyId: string;
		language: ArticleLanguages;
	}[];

	// recommender
	contentLabel: EditorialContentLabels;
	top: boolean;
}

export type OpenSearchArticleStorySlides = TextSlide | QuoteSlide | VideoSlide;
export type OpenSearchMatchStorySlides =
	| (TextSlide & MatchStorySlideBase)
	| (QuoteSlide & MatchStorySlideBase)
	| (VideoSlide & MatchStorySlideBase)
	| (SubSlide & MatchStorySlideBase)
	| (ProgressSlide & MatchStorySlideBase)
	| (GoalSlide & MatchStorySlideBase)
	| (LineupSlide & MatchStorySlideBase)
	| (CardSlide & MatchStorySlideBase);
export type OpenSearchStorySlides = OpenSearchArticleStorySlides | OpenSearchMatchStorySlides;

export interface OpenSearchArticleStory extends OpenSearchStoryBase<OpenSearchArticleStorySlides> {
	storyType: 'article_story';
	sourceArticleId?: string;
}
export interface OpenSearchMatchStory extends OpenSearchStoryBase<OpenSearchMatchStorySlides> {
	storyType: 'match_story';
}

export type OpenSearchStory = OpenSearchArticleStory | OpenSearchMatchStory;
