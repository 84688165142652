import { MonoTypeOperatorFunction, pipe, repeat, retry, timer } from 'rxjs';

export function pollWithBackoff<T>(delay: number, maxDelay: number, count: number): MonoTypeOperatorFunction<T> {
	return pipe(
		retry({
			delay: (_error, i) => {
				const backoffDelay = Math.min(delay * Math.pow(2, i - 1), maxDelay);
				return timer(backoffDelay);
			},
			count
		}),
		repeat({
			delay
		})
	);
}
