<!-- share bar loader -->
<div class="share-container" *ngIf="layout === 'article' && !webview">
	<div class="body animated-background share">
		<div class="background-mask spacer flex"></div>
		<div class="background-mask icon"></div>
		<div class="background-mask spacer"></div>
		<div class="background-mask icon"></div>
		<div class="background-mask spacer"></div>
		<div class="background-mask icon"></div>
		<div class="background-mask spacer"></div>
		<div class="background-mask icon"></div>
		<div class="background-mask spacer"></div>
		<div class="background-mask icon"></div>
		<div class="background-mask spacer flex"></div>
	</div>
</div>
