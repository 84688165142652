import { BundesmasterLanguageRole } from './language-role';
import { BundesmasterUserRole } from './user-role';

export class BundesmasterLanguageRoleValidator {
	constructor(private readonly userRole: BundesmasterUserRole, private readonly languageRoles: readonly BundesmasterLanguageRole[]) {}

	public isMissingLanguageRole(role: BundesmasterUserRole, language: string): boolean {
		return this.userRole.is(role) && !this.languageRoles.some((l) => l.isLanguage(language));
	}
}
