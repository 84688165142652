<div class="club" *ngIf="club">
	<img class="club-logo" width="64" height="64" [ngSrc]="club.logoUrl ?? '/assets/clublogo/default_clublogo.svg'" />
	<div class="text-content">
		<h3>{{ club.displayName }}</h3>
		<p>{{ club.threeLetterCode }}</p>
		<p>{{ club.clubId }}</p>
	</div>
	<a *ngIf="clubLinkVisibility === 'visible'" class="link" mat-icon-button color="primary" [href]="'https://www.bundesliga.com/en/bundesliga/clubs/' + (club.displayName | slugify)" target="_blank" color="primary">
		<mat-icon>open_in_new</mat-icon>
	</a>
</div>
