import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BundesmasterClub } from '@nx-bundesliga/bundesmaster/core';
import { CommonsLibModule } from '@nx-bundesliga/commons';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-club-card',
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatIconModule, CommonsLibModule, NgOptimizedImage],
	templateUrl: './bundesmaster-ui-club-card.component.html',
	styleUrls: ['./bundesmaster-ui-club-card.component.scss']
})
export class BundesmasterUiClubCardComponent {
	@Input() clubLinkVisibility: 'visible' | 'hidden' = 'visible';
	@Input() club: BundesmasterClub;
}
