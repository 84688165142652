import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import messages from '../loading-messages';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-loading-bar-text',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './bundesmaster-ui-loading-bar-text.component.html',
	styleUrls: ['./bundesmaster-ui-loading-bar-text.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundesmasterUiLoadingBarTextComponent {
	@Input() message: string = messages[Math.floor(Math.random() * messages.length)];
}
