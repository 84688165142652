import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';
import { BundesmasterClubApiService } from '../../services/bundesmaster-data-api';

@Pipe({
	name: 'resolveClubId'
})
export class ResolveClubIdPipe implements PipeTransform {
	constructor(private readonly service: BundesmasterClubApiService) {}

	transform(clubId: string): Observable<string> {
		return this.service.getClub(clubId).pipe(
			map((club) => club?.displayName ?? clubId),
			startWith(clubId)
		);
	}
}
