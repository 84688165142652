import { IntersectionStatus } from './intersection-observer';
import { lazyLoadEmbed, lazyLoadEmbedIntersection } from './lazyload-embed';
import { lazyLoadImage, lazyLoadImageIntersection } from './lazyload-image';
import { LazyLoadDirectiveProps } from '../dfl-ngx-lazy-load.directive';

export function lazyLoad(element: HTMLElement | HTMLImageElement | HTMLDivElement, type: string, props: LazyLoadDirectiveProps) {
	if ('image' === type) {
		return lazyLoadImage(<HTMLImageElement | HTMLDivElement>element, props.lazy, props.defaultImagePath, props.errorImagePath, props.offset, props.useSrcset, props.scrollTarget);
	} else {
		return lazyLoadEmbed(element, props.offset, props.scrollTarget);
	}
}

export function lazyLoadIntersection(element: HTMLElement | HTMLImageElement | HTMLDivElement, type: string, props: LazyLoadDirectiveProps) {
	if ('image' === type) {
		return lazyLoadImageIntersection(<HTMLImageElement | HTMLDivElement>element, props.lazy, props.defaultImagePath, props.errorImagePath, props.useSrcset);
	} else {
		return lazyLoadEmbedIntersection(element);
	}
}
