import { Component, Input } from '@angular/core';
/**
 * For DflLoaderTeaserLayouts.teaser
 * * large: hero element on homepage
 * * regular: two-column layout on homepage
 * * small: three-column layout on homepage, load-more
 *
 * DflLoaderTeaserLayouts.club-tile
 * * regular: single tile for club page
 * * small: single tile for club bar on homepage
 */
type DflLoaderTeaserSizes = 'large' | 'regular' | 'small' | 'matchbar';

/**
 * article: hero element in editorial-detail
 * teaser: teaser/list-item used everywhere (e.g. all three-column-grids (load-more on homepage and tabs))
 * matchcenter: header of matchcenter
 * club-header: ...
 * club-tile: ...
 *
 */
type DflLoaderTeaserLayouts = 'article' | 'teaser' | 'matchcenter' | 'club-tile' | 'club-header';

@Component({
	selector: 'dfl-loader-teaser',
	templateUrl: './dfl-loader-teaser.component.html',
	styleUrls: ['./styles/dfl-loader-common.component.scss', './styles/dfl-loader-teaser.component_teaser.scss', './styles/dfl-loader-teaser.component_matchbar.scss']
})
export class DflLoaderTeaserComponent {
	@Input() size: DflLoaderTeaserSizes = 'small';
	@Input() layout: DflLoaderTeaserLayouts = 'teaser';
	@Input() webview = false;
	@Input() migrated = false;

	constructor() {}
}
