import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { BundesmasterClub, BundesmasterClubApiService, completeCompetitionList } from '@nx-bundesliga/bundesmaster/core';
import { BundesmasterUiClubCardComponent } from '@nx-bundesliga/bundesmaster/ui/club-card';
import { BundesmasterUiCompetitionSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/competition-selector';
import { BundesmasterUiSeasonSelectorComponent } from '@nx-bundesliga/bundesmaster/ui/season-selector';
import { BundesmasterUiTeaserPreviewComponent } from '@nx-bundesliga/bundesmaster/ui/teaser-preview';
import { Subject, startWith, switchMap, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'nx-bundesliga-bundesmaster-ui-dialog-select-clubs',
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		FormsModule,
		BundesmasterUiTeaserPreviewComponent,
		BundesmasterUiClubCardComponent,
		MatDialogModule,
		StoreModule,
		MatProgressSpinnerModule,
		MatIconModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatInputModule,
		BundesmasterUiSeasonSelectorComponent,
		BundesmasterUiCompetitionSelectorComponent
	],
	templateUrl: './bundesmaster-ui-dialog-select-clubs.component.html',
	styleUrls: ['./bundesmaster-ui-dialog-select-clubs.component.scss']
})
export class BundesmasterUiDialogSelectClubsComponent implements OnInit, OnDestroy {
	private readonly destroying$ = new Subject<void>();

	public readonly searchInputFormGroup = new FormGroup({
		name: new FormControl(''),
		seasonId: new FormControl(''),
		competitionId: new FormControl('')
	});

	public readonly competitions = completeCompetitionList;

	public hasSelection = false;

	public currentlyFilteredClubIds: string[] = [];

	public clubs$ = this.searchInputFormGroup.valueChanges.pipe(
		takeUntil(this.destroying$),
		startWith({ competitionId: '', seasonId: '', name: '' }),
		switchMap(({ competitionId, seasonId, name }) => this.service.getClubsCollection({ competitionId, name, seasonId })),
		tap(({ items }) => (this.currentlyFilteredClubIds = items.map(({ clubId }) => clubId)))
	);

	private selectedClubs = new Set<string>();

	constructor(public dialogRef: MatDialogRef<BundesmasterUiDialogSelectClubsComponent>, @Inject(MAT_DIALOG_DATA) public data: { readonly selected: readonly string[] }, private readonly service: BundesmasterClubApiService) {}

	ngOnDestroy(): void {
		this.destroying$.next();
		this.destroying$.complete();
	}

	ngOnInit(): void {
		this.selectedClubs = new Set(this.data?.selected ?? []);
	}

	public clearSelection(): void {
		this.selectedClubs.clear();
		this.updateHasSelection();
	}

	private updateHasSelection(): void {
		this.hasSelection = this.selectedClubs.size > 0;
	}

	public selectAll(): void {
		this.currentlyFilteredClubIds.forEach((clubId) => this.selectedClubs.add(clubId));
		this.updateHasSelection();
	}

	public toggleClub({ clubId }: BundesmasterClub) {
		if (this.selectedClubs.has(clubId)) {
			this.selectedClubs.delete(clubId);
		} else {
			this.selectedClubs.add(clubId);
		}

		this.updateHasSelection();
	}

	public isSelected({ clubId }: BundesmasterClub): boolean {
		return this.selectedClubs.has(clubId);
	}

	public add() {
		this.dialogRef.close([...this.selectedClubs.values()]);
	}

	public cancel() {
		this.dialogRef.close(null);
	}

	trackByClubId: TrackByFunction<BundesmasterClub> = (_, { clubId }) => clubId;
}
