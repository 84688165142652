import { Injectable, Pipe, PipeTransform } from '@angular/core';

/**
 * Interface for Tachyon Parameter Objects.
 * Their meaning can be found over at github: https://github.com/humanmade/tachyon#args-reference
 */
interface TachyonParameters {
	fit?: string | number;
	resize?: string;
	w?: number;
	h?: number;
	quality?: number;
	crop_strategy?: 'smart' | 'entropy' | 'attention';
	gravity?: string;
	crop?: boolean | string;
	zoom?: number;
	webp?: boolean;
	lb?: string;
	background?: string;
}

/**
 * Interface for JW Parameter Objects.
 */
interface JWImageParameters {
	width?: string | number;
}

@Injectable()
@Pipe({
	name: 'imageService'
})
export class ImageServicePipe implements PipeTransform {
	/**
	 *
	 * @param src
	 */
	static isJWDomain(value: string): boolean {
		if (value && value !== '') {
			return value.indexOf('cdn.jwplayer.com') > 0 || value.indexOf('assets-jpcust.jwpsrv.com') > 0;
		} else {
			return false;
		}
	}

	/**
	 *
	 * @param src
	 */
	static isTachyonDomain(value: string): boolean {
		if (value && value !== '') {
			return value.indexOf('img.bundesliga') > 0 || value.indexOf('assets.bundesliga') > 0 || value.indexOf('img.bundesliga.com') > 0 || value.indexOf('tchyn.') > 0 || value.indexOf('tachyon.') > 0;
		} else {
			return false;
		}
	}

	/**
	 *
	 * @param src
	 */
	static mappOldImageServiceUrlsToAssetsBundesligaCom(value: string): string {
		if (!value) {
			return '';
		}
		// rewrite i and img.bundesliga.com to assets.bundesliga.com
		if (value.indexOf('i.bundesliga') > 0) {
			return value.replace('i.bundesliga', 'assets.bundesliga');
		} else if (value.indexOf('img.bundesliga') > 0) {
			return value.replace('img.bundesliga', 'assets.bundesliga');
		} else {
			return value;
		}
	}

	/**
	 *
	 * @param src
	 */
	static isBundesligaImageService(value: string): boolean {
		if (value && value !== '') {
			return value.indexOf('assets.bundesliga') > 0;
		} else {
			return false;
		}
	}

	/**
	 *
	 * @param {string} value the url parameters without ? to be transformed into a hashmap
	 */
	static getUrlParamsMap = (value = ``) => {
		const hashes = value.split(`&`);
		return hashes.reduce((params, hash) => {
			const [key, val] = hash.split('=');
			if (key && key !== '') {
				return Object.assign(params, { [key]: decodeURIComponent(val) });
			}
			return params;
		}, {});
	};

	private isNotNull(n: number): boolean {
		return !isNaN(n) && n > 0;
	}

	constructor() {}

	/**
	 * transforms a imageSource to a smaller version, if it's served from tachyon.
	 * all tachyon filters can be found here: https://github.com/humanmade/tachyon
	 *
	 * @param value
	 * @param width
	 * @param height
	 * @param additionalParams
	 * @param stripOnly
	 */
	transform(value: string, width?: number, height?: number, additionalParams?: TachyonParameters, stripOnly?: boolean): string {
		const url = ImageServicePipe.mappOldImageServiceUrlsToAssetsBundesligaCom(value);

		if (!ImageServicePipe.isTachyonDomain(url) && !ImageServicePipe.isJWDomain(url) && !ImageServicePipe.isBundesligaImageService(url)) {
			return url;
		}

		// remove existing parameters
		let srcBase = url.split('?')?.[0];

		if (ImageServicePipe.isTachyonDomain(url)) {
			// rewrite old human made tachyon urls which are inside the firebase
			srcBase = srcBase.replace('/uploads/sites', '/tachyon/sites');

			const params: TachyonParameters = url.split('?').length > 1 ? Object.assign(ImageServicePipe.getUrlParamsMap(url.slice(url.indexOf('?') + 1)), additionalParams || {}) : additionalParams ?? {};
			if (stripOnly) {
				return srcBase;
			}

			// convert resize parameters to fit if they are not set already
			if (params.resize) {
				if (!params.fit) {
					params.fit = params.resize;
				}
				delete params.resize;
			}
			// apply the provided params to the already existing ones, eventually replacing them
			if (this.isNotNull(width) && this.isNotNull(height)) {
				params.fit = `${width},${height}`;
			} else if (this.isNotNull(width)) {
				params.fit = `${width},${width}`;
			} else if (this.isNotNull(height)) {
				params.fit = `${height},${height}`;
			}

			return srcBase + '?' + this._addParameterStringToUrl(params);
		}

		if (ImageServicePipe.isJWDomain(url)) {
			const paramsJW: JWImageParameters = url.split('?').length > 1 ? Object.assign(ImageServicePipe.getUrlParamsMap(url.slice(url.indexOf('?') + 1)), {}) : {};
			if (stripOnly) {
				return srcBase;
			}
			if (this.isNotNull(width)) {
				paramsJW.width = width;
			}
			return srcBase + '?' + this._addParameterStringToUrl(paramsJW);
		}
		return url;
	}

	/**
	 *
	 * @param params
	 * @private
	 */
	private _addParameterStringToUrl(params: TachyonParameters | JWImageParameters): string {
		const createQueryParams = (parameters) =>
			Object.keys(parameters)
				.map((k) => `${k}=${encodeURI(parameters[k])}`)
				.join('&');
		return createQueryParams(params);
	}
}
